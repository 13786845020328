import {
  Box,
  Collapse,
  Divider,
  Paper,
  Table as MuiTable,
  TableBody,
  TableCellProps,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material'
import { ListOrder } from 'api'
import { ThemeColor } from 'app/mui-theme'
import { IconArrowDownShort } from 'assets/icons'
import { IconChevronSelectorVertical } from 'assets/icons'
import { checkScrollToBottom, getValueOrDash } from 'lib/js-utils'
import { Fragment, memo, UIEvent, useState } from 'react'
import { Checkbox } from 'ui/inputs/checkbox'

import { TableCell } from './table-cell'
import { Pagination, TablePagination } from './table-pagination'
import { TableRow, TableRowProps } from './table-row'

export type TableSelected = Array<string>

export type Column = TableCellProps & {
  key: string
  name: React.ReactNode
  sortable?: boolean
}

export type Row = Omit<TableRowProps, 'onClick'> & {
  key: string
  cells: Array<React.ReactNode>
  errors?: Array<string | null>
  onClick?: (key: string) => void
  expandContent?: React.ReactNode
}

export type Props = {
  columns: Array<Column>
  disableHeader?: boolean
  rows: Array<Row>
  noBorder?: boolean
  selected?: TableSelected
  setSelected?: (keys: TableSelected) => void
  pagination?: Pagination
  totalCount?: number
  order?: ListOrder
  setOrder?: (order: ListOrder) => void
  /** common to all rows class name */
  rowsClassName?: string
  /** table-layout: fixed */
  fixed?: boolean
  additionalHeader?: React.ReactNode
  bulkActions?: React.ReactNode
  showBulkActions?: boolean
  isAdditionalHeaderVisible?: boolean
  onScrollToBottom?: () => void
  fullHeight?: boolean
  highlightedHeaderColor?: ThemeColor
  withRowsByPage?: boolean
  withPaginationDivider?: boolean
  tableMaxHeight?: string
}

export const Table = memo(
  ({
    rows,
    columns,
    disableHeader,
    selected,
    setSelected,
    pagination,
    totalCount,
    order,
    setOrder,
    noBorder,
    rowsClassName,
    fixed,
    additionalHeader,
    bulkActions,
    showBulkActions,
    isAdditionalHeaderVisible,
    onScrollToBottom,
    fullHeight,
    highlightedHeaderColor,
    withRowsByPage,
    withPaginationDivider = true,
    tableMaxHeight,
  }: Props) => {
    const [expanded, setExpanded] = useState<Array<string>>([])
    const isExpandableTable = rows.some(row => row.expandContent)

    const handleOrderChange = (columnKey: string) => {
      if (!setOrder) return

      pagination?.onChangePage(0)

      if (!order || order.column !== columnKey) {
        setOrder({ column: columnKey, direction: 'desc' })
      } else if (order.column === columnKey) {
        if (order.direction === 'desc') {
          setOrder({ column: columnKey, direction: 'asc' })
        }

        if (order.direction === 'asc') {
          setOrder(null)
        }
      }
    }

    const handleExpandClick = (rowKey: string) => {
      setExpanded(prevExpanded => {
        const isExpanded = prevExpanded.includes(rowKey)
        if (isExpanded) {
          return prevExpanded.filter(key => key !== rowKey)
        }
        return [...prevExpanded, rowKey]
      })
    }

    return (
      <TableContainer
        onScroll={(event: UIEvent<HTMLDivElement>) => {
          if (onScrollToBottom) {
            checkScrollToBottom(event, onScrollToBottom)
          }
        }}
        component={Paper}
        sx={{
          position: 'relative',
          border: 0,
          borderTop: noBorder || additionalHeader ? 0 : 1,
          borderColor: 'divider',
          borderRadius: 0,
          ...(fullHeight && {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
          }),
          borderBottomLeftRadius: disableHeader ? 0 : 4,
          borderBottomRightRadius: disableHeader ? 0 : 4,
        }}
      >
        {bulkActions && (
          <Box
            position="absolute"
            // eslint-disable-next-line unicorn/no-nested-ternary
            bottom={pagination ? '64px' : rows.length === 1 ? '0px' : '32px'}
            right="32px"
            zIndex={1200}
          >
            <Collapse
              unmountOnExit
              orientation="horizontal"
              in={showBulkActions}
            >
              <Box
                sx={{
                  py: 1,
                  px: 1.5,
                  background: '#F1F1F1E5',
                  boxShadow:
                    '0px 1px 12px 0px #0000001A, 0px 0px 8px 0px #984BDF1A',
                  border: theme => `1px solid ${theme.palette.mineShaft[200]}`,
                  borderRadius: '6px',
                }}
              >
                {bulkActions}
              </Box>
            </Collapse>
          </Box>
        )}

        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            background: theme => theme.palette.background.paper,
          }}
        >
          <Collapse in={isAdditionalHeaderVisible}>
            <Box
              py={1}
              px={2}
              borderBottom={disableHeader ? 0 : 1}
              borderColor="divider"
            >
              {additionalHeader}
            </Box>
          </Collapse>
        </Box>

        <Box display="flex" overflow="auto" maxHeight={tableMaxHeight}>
          <MuiTable
            size="small"
            sx={{
              tableLayout: fixed ? 'fixed' : 'auto',
              borderCollapse: 'separate',
              borderSpacing: '0 6px',
            }}
          >
            {!disableHeader && (
              <TableHead>
                <TableRow
                  sx={{
                    bgcolor: highlightedHeaderColor ?? '#FFF',
                    position: 'sticky',
                    top: 0,
                    zIndex: 1000,
                    background: theme => theme.palette.background.paper,
                  }}
                >
                  {selected && setSelected && (
                    <TableCell width={32} sx={{ pr: 0 }}>
                      <Checkbox
                        small
                        checked={selected.length === rows.length}
                        indeterminate={selected.length > 0}
                        onChange={checked =>
                          setSelected(
                            checked && selected.length === 0
                              ? rows.map(row => row.key)
                              : [],
                          )
                        }
                      />
                    </TableCell>
                  )}

                  {columns.map(({ key, name, sortable, ...columnProps }) => (
                    <TableCell key={key} {...columnProps}>
                      <Box
                        display="inline-flex"
                        alignItems="center"
                        sx={{
                          verticalAlign: 'middle',
                          cursor: sortable ? 'pointer' : 'initial',
                          fontWeight: order?.column === key ? 500 : 400,
                        }}
                        onClick={
                          sortable ? () => handleOrderChange(key) : undefined
                        }
                      >
                        {name}

                        {sortable && (
                          <IconChevronSelectorVertical
                            sx={{
                              fontSize: 14,
                              marginLeft: '10px',
                            }}
                            sort={
                              order?.column === key
                                ? order.direction
                                : undefined
                            }
                          />
                        )}
                      </Box>
                    </TableCell>
                  ))}

                  {isExpandableTable && <TableCell sx={{ width: 16, pr: 0 }} />}
                </TableRow>
              </TableHead>
            )}

            <TableBody>
              {rows.map(
                ({ key, cells, errors, onClick, expandContent, ...rest }) => (
                  <Fragment key={key}>
                    <TableRow
                      hover={!expanded.includes(key)}
                      onClick={() => {
                        if (onClick) {
                          onClick(key)
                        }
                        if (expandContent) {
                          handleExpandClick(key)
                        }
                      }}
                      sx={{ cursor: expandContent ? 'pointer' : 'default' }}
                      className={rowsClassName}
                      {...rest}
                    >
                      {selected && setSelected && (
                        <TableCell
                          noBorder={expanded.includes(key)}
                          width={32}
                          sx={{
                            pr: 0,
                            border: 0,
                          }}
                          onClick={event => event.stopPropagation()}
                        >
                          <Checkbox
                            small
                            checked={selected.includes(key)}
                            onChange={checked =>
                              setSelected(
                                checked
                                  ? [...selected, key]
                                  : selected.filter(
                                      selectedRow => selectedRow !== key,
                                    ),
                              )
                            }
                          />
                        </TableCell>
                      )}

                      {cells.map((cell, index) => {
                        const column = columns[index]
                        const error = errors ? errors[index] : null

                        if (!column) return null

                        const {
                          key: columnKey,
                          name,
                          sortable,
                          ...columnProps
                        } = column

                        const cellValue = getValueOrDash(cell)
                        return (
                          <TableCell
                            noBorder={expanded.includes(key)}
                            {...columnProps}
                            key={columnKey}
                            sx={{
                              background: error ? '#FEE6E7' : undefined,
                              border: 0,
                            }}
                          >
                            {cellValue}
                            {error && (
                              <Typography variant="caption" color="error">
                                {error}
                              </Typography>
                            )}
                          </TableCell>
                        )
                      })}

                      {isExpandableTable && (
                        <TableCell noBorder>
                          {expandContent && (
                            <IconArrowDownShort
                              sx={{
                                color: '#000000',
                                fontSize: 18,
                                transform: expanded.includes(key)
                                  ? 'rotate(180deg)'
                                  : '',
                                transition: '.3s',
                              }}
                            />
                          )}
                        </TableCell>
                      )}
                    </TableRow>

                    {expandContent && (
                      <TableRow>
                        <TableCell
                          noBorder={!expanded.includes(key)}
                          sx={{
                            padding: 0,
                            height: expanded.includes(key) ? 'auto' : 0,
                          }}
                          colSpan={
                            selected ? cells.length + 2 : cells.length + 1
                          }
                        >
                          <Collapse in={expanded.includes(key)}>
                            {expandContent}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ),
              )}
            </TableBody>
          </MuiTable>
        </Box>

        {pagination && totalCount !== undefined && totalCount > 10 && (
          <Box
            sx={{
              position: 'sticky',
              bottom: 0,
              zIndex: 1000,
              background: theme => theme.palette.background.paper,
            }}
          >
            {withPaginationDivider && <Divider />}

            <Box py={1.5} px={3}>
              <TablePagination
                pagination={pagination}
                totalCount={totalCount}
                withRowsByPage={withRowsByPage}
              />
            </Box>
          </Box>
        )}
      </TableContainer>
    )
  },
)
