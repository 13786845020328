import { buildModuleCacheKey } from 'lib/react-query-utils'

import {
  GetActivitiesListInput,
  GetContactActivitiesInput,
  GetWeeklyActivitiesInput,
} from './activities'
import {
  GetSocialProviderLinkInput,
  GetUserByConfirmationRequestInput,
  GetUserDetailsInput,
  GetUsersListInput,
} from './auth'
import {
  GetCareerSiteConfigByCompanySlugInput,
  GetPublishedCompanyInput,
  GetPublishedJobInput,
  GetPublishedJobsListInput,
} from './career-site'
import {
  GetCompanyChargesListInput,
  GetCompanyInvoicesListInput,
  GetCompanySkillDetailsInput,
  GetCompanySkillsListInput,
  GetCustomFieldsInput,
  GetDonorCompaniesInput,
  GetTeamWorkloadInput,
} from './company'
import {
  GetCalendarFreeTimeSlotsInput,
  GetContactAppliedJobsInput,
  GetContactCommentsInput,
  GetContactCompletenessInfoInput,
  GetContactDetailsInput,
  GetContactHiringExperienceInput,
  GetContactHistoryInput,
  GetContactPlaceholderInput,
  GetContactsListInput,
  GetContactsTalentsPoolDetailsInput,
  GetContactsTalentsPoolsList,
  GetContactTalentsPoolsInput,
  GetContactWorkExperienceInput,
  GetHistoryFiltersInput,
  GetImportContractsInput,
  GetImportHistoryInput,
  GetImportSessionInput,
  GetSavedFiltersInput,
  SearchContactsInput,
} from './contacts'
import { EmailTemplateInput, EmailTemplatesListInput } from './email-templates'
import { GetFileInput } from './files'
import { GetJobRequestInput, GetJobRequestsListInput } from './job-requests'
import {
  GetJobDetailsInput,
  GetJobFromJobRequestInput,
  GetJobHistoryInput,
  GetJobPipelineContactsListInput,
  GetJobsListInput,
  GetUsersForJobShareInput,
  SearchJobsInput,
} from './jobs'
import {
  GetContactMailboxesInput,
  GetMailboxThreadsInput,
  GetThreadDetailsInput,
} from './mailbox'
import { GetMyNotificationsInput } from './notifications'
import {
  GetContactsAddedByPositionReportInput,
  GetContactsHiredBySourceInput,
  GetGenericReportInput,
  GetRecruiterReportInput,
  GetReportInput,
} from './reports'
import { GlobalSearchHistoryInput, GlobalSearchInput } from './search'
import { GetCitiesListInput } from './search/cities-search.api'
import { SubscriptionChangePriceInput } from './subscriptions'

/**
 * Same list can be displayed with the standard page pagination in one place (useQuery)
 * and with the infinite scroll pagination in another place (useInfiniteQuery)
 * We want to keep appropriate query cache entries for them separately
 * So we add { infinite: true } to the query key used with useInfiniteQuery
 */
export type InfiniteQueryKey = {
  infinite?: true
}

/**
 * `qk` stands for "query keys"
 */
export const qk = buildModuleCacheKey({
  auth: {
    users: {
      myProfile: null,
      list: (input: GetUsersListInput & InfiniteQueryKey) => [input],
      listCount: null,
      details: (input: GetUserDetailsInput) => [input],
      integrations: null,
    },

    roles: {
      list: null,
    },

    registration: {
      companyIndustries: null,
      companySizes: null,
      getSocialLink: (input: GetSocialProviderLinkInput) => [input],
    },

    confirmation: {
      invitedUser: (input: GetUserByConfirmationRequestInput) => [input],
    },

    token: {
      refresh: null,
      refreshInfo: null,
    },
  },

  contacts: {
    details: (input: GetContactDetailsInput) => [input],
    list: (input: GetContactsListInput & InfiniteQueryKey) => [input],
    search: (input: SearchContactsInput & InfiniteQueryKey) => [input],
    talentsPools: (input: GetContactTalentsPoolsInput) => [input],
    appliedJobs: (input: GetContactAppliedJobsInput) => [input],
    hiringExperience: (input: GetContactHiringExperienceInput) => [input],
    amountOfAddedContacts: null,
    completeness: (input: GetContactCompletenessInfoInput) => [input],
    logs: (input: GetContactHistoryInput) => [input],
    workExperience: (input: GetContactWorkExperienceInput) => [input],
    comments: (input: GetContactCommentsInput & InfiniteQueryKey) => [input],
    placeholder: (input: GetContactPlaceholderInput) => [input],
    cv: (input: { file: string }) => [input],

    talentPools: {
      list: (input: GetContactsTalentsPoolsList & InfiniteQueryKey) => [input],
      details: (input: GetContactsTalentsPoolDetailsInput) => [input],
      users: (input: GetContactsTalentsPoolDetailsInput) => [input],
    },

    filters: {
      history: (input: GetHistoryFiltersInput) => [input],
      saved: (input: GetSavedFiltersInput) => [input],
    },

    import: {
      fields: null,
      template: null,
      sessions: (input: GetImportHistoryInput) => [input],
      contacts: (input: GetImportContractsInput) => [input],
      session: (input: GetImportSessionInput) => [input],
    },

    bookMeeting: {
      freeTimeSlots: (input: GetCalendarFreeTimeSlotsInput) => [input],
    },
  },

  jobs: {
    details: (input: GetJobDetailsInput) => [input],
    list: (input: GetJobsListInput & InfiniteQueryKey) => [input],
    history: (input: GetJobHistoryInput) => [input],
    search: (input: SearchJobsInput) => [input],
    share: (input: GetUsersForJobShareInput) => [input],
    jobFromJobRequest: (input: GetJobFromJobRequestInput) => [input],
    pipeline: {
      contactsList: (input: GetJobPipelineContactsListInput) => [input],
    },
  },

  careerSite: {
    publishedJob: (input: GetPublishedJobInput) => [input],
    publishedJobsList: (input: GetPublishedJobsListInput) => [input],
    publishedCompany: (input: GetPublishedCompanyInput) => [input],
    configuration: null,
    configurationBySlug: (input: GetCareerSiteConfigByCompanySlugInput) => [
      input,
    ],
  },

  reports: {
    jobs: {
      jobsByLevelAndClosureStatus: (input: GetReportInput) => [input],
      jobsAverageOverdue: (input: GetReportInput) => [input],
      jobsClosedPerQuarter: (input: GetReportInput) => [input],
      jobsClosedByLevel: (input: GetReportInput) => [input],
      jobsClosedBySource: (input: GetReportInput) => [input],
      jobsClosedByDepartment: (input: GetReportInput) => [input],
      jobsClosedByUser: (input: GetReportInput) => [input],
      jobsAmountByStatus: (input: GetGenericReportInput) => [input],
      myRecruiterJobsClosure: (input: GetReportInput) => [input],
      recruiterJobsClosure: (input: GetRecruiterReportInput) => [input],
      recruiterJobPerformance: (input: GetRecruiterReportInput) => [input],
    },
    contacts: {
      contactsByQualificationLevel: (input: GetReportInput) => [input],
      contactsAddedByQuarter: (input: GetReportInput) => [input],
      contactsHiredByLevel: (input: GetGenericReportInput) => [input],
      contactsHiredBySource: (input: GetContactsHiredBySourceInput) => [input],
      contactsAddedToTalentsPool: (input: GetReportInput) => [input],
      contactsAddedByPosition: (
        input: GetContactsAddedByPositionReportInput,
      ) => [input],
      recruiterContactPerformance: (input: GetGenericReportInput) => [input],
      recruiterContactsHiredByPosition: (input: GetGenericReportInput) => [
        input,
      ],
    },
  },

  files: {
    file: (input: GetFileInput) => [input],
  },

  company: {
    industries: null,
    sizes: null,
    details: null,
    name: null,
    offices: null,
    projects: null,
    positions: null,
    sources: null,
    departments: null,
    departmentsForApprover: null,
    languages: null,
    jobTypes: null,
    workExperiences: null,
    rejectReasons: null,
    approvers: null,
    emailIntegration: null,
    schedulingLinks: {
      meetingDetails: null,
      regularAvailability: null,
      specificAvailability: null,
    },
    skills: {
      list: (input: GetCompanySkillsListInput & InfiniteQueryKey) => [input],
      details: (input: GetCompanySkillDetailsInput) => [input],
      groups: null,
    },
    pipelineTemplates: {
      list: null,
    },
    KPISettings: null,
    donorCompanies: (input: GetDonorCompaniesInput) => [input],
    paymentMethods: null,
    customFields: (input: GetCustomFieldsInput) => [input],
  },

  teamsDashboard: {
    workloads: (input: GetTeamWorkloadInput & InfiniteQueryKey) => [input],
  },

  notifications: {
    list: (input: GetMyNotificationsInput) => [input],
    count: null,
    feed: null,
  },

  dictionaries: null,

  globalSearch: {
    search: (input: GlobalSearchInput) => [input],
    history: (input: GlobalSearchHistoryInput & InfiniteQueryKey) => [input],
    cities: (input: GetCitiesListInput & InfiniteQueryKey) => [input],
  },

  jobRequests: {
    details: (input: GetJobRequestInput) => [input],
    list: (input: GetJobRequestsListInput & InfiniteQueryKey) => [input],
    createdJobs: (input: GetJobRequestInput) => [input],
  },

  activities: {
    list: (input: GetActivitiesListInput & InfiniteQueryKey) => [input],
    contact: (input: GetContactActivitiesInput) => [input],
    weeklyActivities: (input: GetWeeklyActivitiesInput & InfiniteQueryKey) => {
      return [input]
    },
  },

  subscriptions: {
    currentSubscription: null,
    availableSubscriptions: null,
    default: null,
    changePrice: (input: SubscriptionChangePriceInput) => [input],
    charges: (input: GetCompanyChargesListInput) => [input],
    invoices: (input: GetCompanyInvoicesListInput) => [input],
    promotion: null,
  },

  external: {
    dataByIP: null,
  },

  timeZones: {
    list: null,
  },

  mailbox: {
    threads: (input: GetMailboxThreadsInput) => [input],
    threadDetails: (input: GetThreadDetailsInput) => [input],
    unreadEmailsCount: null,
    contactMailboxes: (input: GetContactMailboxesInput) => [input],
  },

  emailTemplates: {
    list: (input: EmailTemplatesListInput) => [input],
    details: (input: EmailTemplateInput) => [input],
  },
})
