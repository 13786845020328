import {
  Tooltip,
  TooltipProps,
  Typography,
  TypographyProps,
} from '@mui/material'
import { IconCopyOutline } from 'assets/icons'
import { useIsElementOverflow } from 'lib/react-utils'
import { IconButton } from 'ui/inputs/icon-button'

import { CopyText } from './copy-text'

export const TruncateAndCopy = ({
  sx,
  text,
  isCopyButtonAlwaysVisible = false,
  tooltipPlacement = 'top',
  copyTooltipPlacement = 'bottom',
  ...rest
}: Omit<TypographyProps, 'children'> & {
  text: string
  isCopyButtonAlwaysVisible?: boolean
  tooltipPlacement?: TooltipProps['placement']
  copyTooltipPlacement?: TooltipProps['placement']
}) => {
  const { ref, isOverflow } = useIsElementOverflow('horizontal')

  return (
    <Tooltip title={isOverflow ? text : ''} placement={tooltipPlacement}>
      <Typography
        variant="inherit"
        color="inherit"
        {...rest}
        ref={ref}
        noWrap
        sx={{
          ...sx,
          ...((isOverflow || isCopyButtonAlwaysVisible) && {
            position: 'relative',
            pr: 3,

            '&:hover .copy-button': {
              opacity: 1,
            },
          }),
        }}
      >
        {text}
        {(isOverflow || isCopyButtonAlwaysVisible) && (
          <CopyText
            text={text}
            tooltipPlacement={copyTooltipPlacement}
            containerProps={{
              className: 'copy-button',
              sx: {
                position: 'absolute',
                top: '50%',
                right: 0,
                transform: 'translateY(-50%)',
                display: 'flex',
                opacity: isCopyButtonAlwaysVisible ? 1 : 0,
                transition: 'opacity 150ms',
              },
            }}
          >
            <IconButton
              disablePadding
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.preventDefault()
              }}
            >
              <IconCopyOutline
                sx={{
                  color: 'inherit',
                  fontSize: '18px',
                }}
              />
            </IconButton>
          </CopyText>
        )}
      </Typography>
    </Tooltip>
  )
}
