export const scopes = {
  /* SETTINGS */
  canViewCompanySettings: 'company-read',
  canManageCompany: 'company-manage',
  canSetUpCompany: 'company-setup',
  canEditCareerSiteSettings: 'career-site-edit',

  /* INTEGRATIONS */
  canViewEmailIntegration: 'company-integration-read',
  canManageCompanyIntegration: 'company-integration-manage',
  canManageProfileIntegration: 'profile-integration-manage',
  canViewUserIntegration: 'profile-integration-read',
  canManageOwnSettings: 'profile-manage',
  canViewOwnSettings: 'profile-read',

  /* USERS */
  canChangeUserStatus: 'user-status-manage',
  canManageUser: 'user-manage',
  canViewUserList: 'user-read',

  /* USER INVITE */
  canManageUserInvite: 'invite-manage',
  canViewUserInvite: 'invite-read',

  /* SUBSCRIPTIONS */
  canViewSubscriptions: 'billing-read',

  /* SCHEDULE SETTINGS */
  canManageSchedule: 'schedule-manage',

  /* BILLING */
  canManagePaymentMethods: 'billing-manage',

  /* Company */
  canViewCompanyConfiguration: 'company-configuration-read',
  canManageCompanyConfiguration: 'company-configuration-manage',

  /* CONTACTS */
  canViewContacts: 'contact-read',
  canManageContact: 'contact-manage',
  canImportContacts: 'contact-import',
  canUploadCv: 'cv-parse',
  /* TALENTS POOLS */
  canManageTalentsPool: 'talent-pool-manage',
  canViewTalentsPool: 'talent-pool-read',

  /* JOBS */
  canViewJobs: 'job-read',
  canUpdateJob: 'job-update',
  canManageJob: 'job-manage',
  canPublishJob: 'job-publish',

  /* JOB REQUEST */
  canViewJobRequests: 'job-request-read',
  canManageJobRequest: 'job-request-manage',

  /* ACTIVITIES */
  canViewActivities: 'activity-read',
  canManageActivity: 'activity-manage',

  /* REPORTS */
  canGetRecruiterPerformanceReport: 'report-read',
  canViewMyRecruiterPerformance: 'report-own-read',

  /* MY RECRUITER PERFORMANCE DASHBOARD */
  canViewMyRecruiterDashboard: 'dashboard-read',

  /* TEAMS PERFORMANCE DASHBOARD */
  canViewDashboardTeam: 'dashboard-team-read',

  /* NOTIFICATIONS */
  canReadNotifications: 'notification-read',
  canManageNotifications: 'notification-manage',

  /* EMAIL TEMPLATES */
  canViewEmailTemplate: 'email-template-read',
  canManageEmailTemplate: 'email-template-manage',

  /* MAILBOX */
  canReadMailbox: 'mailbox-read',

  /* FILTER */
  canManageFilter: 'filter-manage',

  /* File */
  canManageFile: 'file-manage',
  canViewFile: 'file-read',
} as const
