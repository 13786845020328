import { usePDF } from '@react-pdf/renderer'
import { JobContactScreeningQuestion } from 'api'
import { saveAs } from 'file-saver'
import { useDateAndTimeFormat } from 'lib/context'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'

import { ContactApplicationPDF } from './contact-application-pdf'
import { ContactDetailsView } from './contact-details-view'

type Props = Readonly<{
  isOpen: boolean
  onClose: () => void
  contactDetails: {
    fullName: string
    jobName: string
    appliedAt: Date
    screeningQuestions?: Array<JobContactScreeningQuestion>
  }
}>

export const ContactApplicationDialog = ({
  isOpen,
  onClose,
  contactDetails,
}: Props) => {
  const { t } = useTranslation()

  const { formatDate } = useDateAndTimeFormat()

  const details = [
    {
      label: t(
        'jobs_pipeline.contact_application_dialog.detail_labels.contact_name',
      ),
      value: contactDetails.fullName,
    },
    {
      label: t(
        'jobs_pipeline.contact_application_dialog.detail_labels.job_name',
      ),
      value: contactDetails.jobName,
    },
    {
      label: t(
        'jobs_pipeline.contact_application_dialog.detail_labels.applied_at_date',
      ),
      value: formatDate(contactDetails.appliedAt),
    },
    ...(contactDetails.screeningQuestions?.map(questionDetails => ({
      label: questionDetails.questionText,
      value: questionDetails.answers
        .map(answer => {
          if (questionDetails.answerType === 'DATE') {
            return formatDate(new Date(answer as string))
          }

          return answer
        })
        .join(', '),
    })) ?? []),
  ]

  const [instance] = usePDF({
    document: <ContactApplicationPDF details={details} />,
  })

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent
        onDeny={onClose}
        title={t('jobs_pipeline.contact_application_dialog.title')}
        confirmText={t('jobs_pipeline.contact_application_dialog.button')}
        isActionLoading={instance.loading}
        onConfirm={() => {
          if (instance.blob) {
            saveAs(
              instance.blob,
              `${contactDetails.fullName}_${contactDetails.jobName}_Application form.pdf`,
            )
          }
        }}
      >
        <ContactDetailsView details={details} />
      </DialogContent>
    </Dialog>
  )
}
