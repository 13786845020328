import {
  TLanguage,
  TProfile,
  TQualificationLevelCode,
  TSkill,
} from 'api/common'
import {
  TCompanyDepartment,
  TCompanyJobType,
  TCompanyOffice,
  TCompanyPosition,
  TCompanyProject,
  TCustomFieldWithValue,
  TJobPipelineStage,
} from 'api/company'
import * as t from 'io-ts'
import { DateFromISOString, optionFromNullable } from 'io-ts-types'

export const TJobStatusCode = t.union([
  t.literal('OPEN'),
  t.literal('DRAFT'),
  t.literal('CLOSED'),
  t.literal('ARCHIVED'),
])

export type JobStatusCode = t.TypeOf<typeof TJobStatusCode>

export const TJobPriority = t.union([
  t.literal('LOW'),
  t.literal('MEDIUM'),
  t.literal('HIGH'),
  t.literal('FREEZE'),
])

export type JobPriority = t.TypeOf<typeof TJobPriority>

export const TWorkModel = t.union([
  t.literal('OFFICE-BASED'),
  t.literal('REMOTE'),
  t.literal('HYBRId'),
])

export type WorkModel = t.TypeOf<typeof TWorkModel>

export const TJobPermissionType = t.union([
  t.literal('VIEWER'),
  t.literal('COLLABORATOR'),
])

export type JobPermissionType = t.TypeOf<typeof TJobPermissionType>

export const TJobPerformingStatuses = t.union([
  t.literal('TIMELY'),
  t.literal('UPCOMING'),
  t.literal('APPROACHING'),
  t.literal('OVERDUE'),
])

export type JobPerformingStatuses = t.TypeOf<typeof TJobPerformingStatuses>

export const TJobSharedWithUsers = t.record(
  TJobPermissionType,
  t.union([t.array(TProfile), t.undefined]),
)

export const ScreeningQuestionAnswerTypeCodec = t.union([
  t.literal('TEXT'),
  t.literal('NUMBER'),
  t.literal('DATE'),
  t.literal('SINGLE_CHOICE'),
])

export const ScreeningQuestionCodec = t.intersection([
  t.strict({
    questionText: t.string,
    answerType: ScreeningQuestionAnswerTypeCodec,
    disqualifying: t.boolean,
    required: t.boolean,
  }),
  t.partial({
    fromDate: DateFromISOString,
    toDate: DateFromISOString,
    minNumber: t.number,
    maxNumber: t.number,
    options: t.array(
      t.strict({
        option: t.string,
        disqualify: t.boolean,
      }),
    ),
  }),
])

export type ScreeningQuestion = t.TypeOf<typeof ScreeningQuestionCodec>

export const TJobDetails = t.intersection([
  t.strict({
    jobId: t.string,
    position: TCompanyPosition,
    jobType: TCompanyJobType,
    office: TCompanyOffice,
    recruiters: t.array(TProfile),
    targetDate: DateFromISOString,
    qualificationLevel: t.strict({
      code: TQualificationLevelCode,
      name: t.string,
    }),
    jobPipelineStages: t.array(TJobPipelineStage),
    description: optionFromNullable(t.string),
    status: TJobStatusCode,
    createdAt: DateFromISOString,
    priority: TJobPriority,
    sharedWith: TJobSharedWithUsers,
    modifiable: t.boolean,
    performing: TJobPerformingStatuses,
    department: TCompanyDepartment,
    languages: t.array(TLanguage),
    published: t.boolean,
    creator: TProfile,
    model: t.strict({
      code: t.string,
      name: t.string,
    }),
  }),
  t.partial({
    salaryMin: t.number,
    salaryMax: t.number,
    qualificationLevel: t.strict({
      code: t.string,
      name: t.string,
    }),
    skills: t.array(TSkill),
    hiringManager: TProfile,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
    project: TCompanyProject,
    customFields: t.array(TCustomFieldWithValue),
    jobRequestId: t.string,
    publicLink: t.string,
    screeningQuestions: t.array(ScreeningQuestionCodec),
  }),
])

export type JobDetails = t.TypeOf<typeof TJobDetails>

export const TJobRow = t.intersection([
  t.strict({
    jobId: t.string,
    position: TCompanyPosition,
    status: TJobStatusCode,
    department: TCompanyDepartment,
    performing: TJobPerformingStatuses,
    statistics: t.strict({
      daysOpen: t.number,
      appliedCandidatesCountTotal: t.number,
    }),
    recruiters: t.array(TProfile),
    priority: TJobPriority,
    targetDate: DateFromISOString,
    published: t.boolean,
  }),
  t.partial({
    project: TCompanyProject,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
  }),
])

export type JobRow = t.TypeOf<typeof TJobRow>

export const TJobFromJobRequest = t.intersection([
  t.strict({
    position: TCompanyPosition,
    jobType: TCompanyJobType,
    office: TCompanyOffice,
    department: TCompanyDepartment,
    recruiters: t.array(
      t.strict({
        fullName: t.string,
        profileId: t.string,
      }),
    ),
    description: t.string,
    targetDate: DateFromISOString,
    model: t.strict({
      code: t.string,
      name: t.string,
    }),
    languages: t.array(TLanguage),
  }),
  t.partial({
    project: TCompanyProject,
    skills: t.array(TSkill),
    salaryMin: t.number,
    salaryMax: t.number,
    address: t.partial({
      country: t.strict({ code: t.string }),
      city: t.string,
    }),
    hiringManager: TProfile,
    qualificationLevel: t.strict({
      code: t.string,
      name: t.string,
    }),
  }),
])

export type JobFromJobRequest = t.TypeOf<typeof TJobFromJobRequest>

const TJobHistoryLogType = t.union([
  t.literal('JOB_CREATED'),
  t.literal('JOB_STATUS_CHANGED'),
  t.literal('JOB_CONTACT_APPLIED'),
  t.literal('JOB_CONTACT_MOVED'),
  t.literal('JOB_CONTACT_REJECTED'),
  t.literal('JOB_CONTACT_RESTORED'),
  t.literal('JOB_SHARED'),
  t.literal('JOB_UNSHARED'),
  t.literal('JOB_CONTACT_HIRED'),
  t.literal('JOB_UPDATED'),
  t.literal('JOB_PUBLISHED_BY_USER'),
  t.literal('JOB_UNPUBLISHED_BY_USER'),
  t.literal('JOB_UNPUBLISHED_BY_SYSTEM'),
])

export type JobHistoryLogType = t.TypeOf<typeof TJobHistoryLogType>

export const TJobHistoryLog = t.strict({
  id: t.string,
  message: t.string,
  subject: t.string,
  createdAt: DateFromISOString,
  type: TJobHistoryLogType,
})

export const TJobsSearchResult = t.array(
  t.intersection([
    t.strict({
      jobId: t.string,
      jobStatus: TJobStatusCode,
      positionName: t.string,
      targetDate: DateFromISOString,
      recruiters: t.array(TProfile),
    }),
    t.partial({
      projectName: t.string,
    }),
  ]),
)

export const TPublishJob = t.strict({
  url: t.string,
})

export const TJobDescription = t.strict({ description: t.string })
