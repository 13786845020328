import { forwardRef } from 'react'
import {
  FormControl,
  FormControlWrapper,
  InputBase,
  InputBaseProps,
} from 'ui/inputs/common'

export type TextFieldProps = FormControlWrapper &
  Omit<InputBaseProps, 'value' | 'onChange'> & {
    value: string
    onChange: (value: string) => void
    hint?: string
  }

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      label,
      error,
      helperText,
      onChange,
      id,
      onRemove,
      required,
      name,
      hint,
      minWidth,
      sx,
      ...rest
    },
    ref,
  ) => {
    return (
      <FormControl
        label={label}
        error={error}
        helperText={helperText}
        onRemove={onRemove}
        required={required}
        name={name}
        hint={hint}
        minWidth={minWidth}
      >
        <InputBase
          {...rest}
          sx={{ ...sx, minWidth }}
          ref={ref}
          id={id ?? label}
          onChange={event => onChange(event.target.value)}
          error={error}
          required={required}
        />
      </FormControl>
    )
  },
)
