import { Stack, Typography } from '@mui/material'

type Props = Readonly<{
  details: Array<{
    label: string
    value: string
  }>
}>

export const ContactDetailsView = ({ details }: Props) => {
  return (
    <Stack spacing={2}>
      {details.map(({ label, value }) => {
        return (
          <Typography key={label + value} variant="body2">
            <Typography component="span" variant="body1">
              {label}:
            </Typography>{' '}
            {value}
          </Typography>
        )
      })}
    </Stack>
  )
}
