import { Box, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getCompanyDetails, markCompanySettingsAsConfigured, qk } from 'api'
import { useScopes } from 'app/scopes'
import laptopSearchImage from 'assets/images/laptop-search.png'
import laptopSuccessImage from 'assets/images/laptop-success.png'
import { useBoolean } from 'lib/react-utils'
import mixpanel from 'mixpanel-browser'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'

import { ManualSettingsDialog } from './manual-settings-setup'

export const SettingsPackage = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  // const { startTour, skipSettingsSteps } = useIntroductionTour()

  const { canManageCompany } = useScopes()

  const $company = useQuery(qk.company.details.toKey(), getCompanyDetails, {
    enabled: canManageCompany,
  })
  const refetchCompany = $company.refetch

  const $markAsConfigured = useMutation(markCompanySettingsAsConfigured)

  const quickStartSelected = useBoolean()
  const isSettingUpManually = useBoolean()

  const handleManualSetupSuccess = useCallback(async () => {
    mixpanel.track('Configure company settings', {
      'Configuration method': 'Set up manually',
    })

    await refetchCompany()
    await queryClient.invalidateQueries(qk.company.offices.toKey())

    isSettingUpManually.setFalse()
    // after manual settings setup, user should not see settings part of introduction tour
    // skipSettingsSteps()
    // startTour()
  }, [refetchCompany, queryClient, isSettingUpManually])

  if (!$company.data) return null

  return (
    <>
      <Dialog
        open={!$company.data.packageConfigured && isSettingUpManually.isFalse}
        width="520px"
      >
        <DialogContent
          title={
            quickStartSelected.isTrue
              ? t('manual_settings_setup.settings_package.success_title')
              : t('manual_settings_setup.settings_package.title')
          }
          isWithCloseIconButton={false}
          onDeny={
            quickStartSelected.isFalse ? isSettingUpManually.setTrue : undefined
          }
          onConfirm={async () => {
            if (quickStartSelected.isTrue) {
              await refetchCompany()
              // startTour()
            } else {
              $markAsConfigured.mutate(undefined, {
                onSuccess: () => {
                  mixpanel.track('Configure company settings', {
                    'Configuration method': 'Quick start',
                  })

                  quickStartSelected.setTrue()
                },
              })
            }
          }}
          isActionLoading={$markAsConfigured.isLoading || $company.isRefetching}
          denyText={t('manual_settings_setup.settings_package.set_up_manually')}
          confirmText={
            quickStartSelected.isTrue
              ? t('common.done')
              : t('manual_settings_setup.settings_package.quick_start')
          }
        >
          <Box>
            <Typography color="text.secondary" mb={2}>
              {quickStartSelected.isTrue
                ? t(
                    'manual_settings_setup.settings_package.success_description',
                  )
                : t('manual_settings_setup.settings_package.description')}
            </Typography>
            <Box
              component="img"
              alt="Laptop"
              src={
                quickStartSelected.isTrue
                  ? laptopSuccessImage
                  : laptopSearchImage
              }
              sx={{ display: 'block', width: 240, mx: 'auto' }}
            />
          </Box>
        </DialogContent>
      </Dialog>

      {isSettingUpManually.isTrue && (
        <ManualSettingsDialog
          onCancel={isSettingUpManually.setFalse}
          onSuccess={handleManualSetupSuccess}
        />
      )}
    </>
  )
}
