import { Tooltip, Typography } from '@mui/material'
import { SkillLevelCode } from 'api'
import {
  IconLevelsCritical,
  IconLevelsHigh,
  IconLevelsHighest,
  IconLevelsMedium,
} from 'assets/icons'
import { TFuncKey, useTranslation } from 'react-i18next'
import { Badge } from 'ui/data'

type LevelIndicator = {
  icon: React.ReactNode
  title: TFuncKey
  bgColor?: string
}

export const skillLevelIndicators: { [key in SkillLevelCode]: LevelIndicator } =
  {
    GOOD: {
      icon: <IconLevelsMedium sx={{ color: 'blue.main', fontSize: '14px' }} />,
      title: 'skills.levels.good_knowledge',
      bgColor: '#CDE9F9',
    },
    EXPERIENCED: {
      icon: <IconLevelsHigh sx={{ color: 'success.main', fontSize: '14px' }} />,
      title: 'skills.levels.experienced',
      bgColor: '#CCEFE0',
    },
    VERY_EXPERIENCED: {
      icon: (
        <IconLevelsHighest
          sx={{ color: 'flushOrange.main', fontSize: '14px' }}
        />
      ),
      title: 'skills.levels.very_experienced',
      bgColor: '#FFE6CC',
    },
    EXPERT: {
      icon: <IconLevelsCritical sx={{ color: '#4D00F0', fontSize: '14px' }} />,
      title: 'skills.levels.expert',
      bgColor: '#EADBF9',
    },
  }

export const SkillBadge = ({
  skillName,
  skillLevelCode,
}: {
  skillName: string
  skillLevelCode: SkillLevelCode
}) => {
  const { t } = useTranslation()
  const detectedSkill = skillLevelIndicators[skillLevelCode]

  return (
    <Tooltip
      arrow
      placement="top-start"
      title={
        <Typography variant="caption" sx={{ p: 0.5 }}>
          {t(detectedSkill.title, { skillName }).toString()}
        </Typography>
      }
    >
      <Badge
        icon={detectedSkill.icon}
        background="white"
        sx={{ border: '1px solid #E5E5E8', borderRadius: '4px' }}
      >
        {skillName}
      </Badge>
    </Tooltip>
  )
}
