import { Box, ButtonBase, Stack, Typography } from '@mui/material'
import { CornerStyle } from 'api'
import { useTranslation } from 'react-i18next'

export const cornerStyles: Array<{
  cornerStyle: CornerStyle
  borderRadius: string
}> = [
  {
    cornerStyle: 'ROUNDED',
    borderRadius: '8px',
  },
  {
    cornerStyle: 'SHARP',
    borderRadius: '0px',
  },
  {
    cornerStyle: 'CIRCULAR',
    borderRadius: '100px',
  },
]

type Props = Readonly<{
  cornerStyle: CornerStyle
  onChange: (cornerStyle: CornerStyle) => void
}>

export const CornerStyleSelect = ({
  cornerStyle: selectedCornerStyle,
  onChange,
}: Props) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" spacing={2}>
      {cornerStyles.map(({ cornerStyle, borderRadius }) => {
        return (
          <ButtonBase
            key={cornerStyle}
            onClick={() => onChange(cornerStyle)}
            sx={{
              width: '72px',
              height: '72px',
              paddingTop: '8px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid',
              borderRadius: '12px',
              background: '#F9FBFC',
              borderColor: theme =>
                cornerStyle === selectedCornerStyle
                  ? theme.palette.mediumPurple.main
                  : '#E5E5E5',
            }}
          >
            <Box
              sx={{
                width: '24px',
                height: '24px',
                borderColor: 'black',
                borderTopRightRadius: borderRadius,
                borderRight: '3px solid',
                borderTop: '3px solid',
                mb: '8px',
              }}
            />

            <Typography textAlign="center" variant="body1" fontSize="12px">
              {t(`common.corner_styles.${cornerStyle}`)}
            </Typography>
          </ButtonBase>
        )
      })}
    </Stack>
  )
}
