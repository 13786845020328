import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconSettingsCareerSite = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M18.3337 7.5H1.66699M1.66699 6.5L1.66699 13.5C1.66699 14.9001 1.66699 15.6002 1.93948 16.135C2.17916 16.6054 2.56161 16.9878 3.03202 17.2275C3.56679 17.5 4.26686 17.5 5.66699 17.5H14.3337C15.7338 17.5 16.4339 17.5 16.9686 17.2275C17.439 16.9878 17.8215 16.6054 18.0612 16.135C18.3337 15.6002 18.3337 14.9001 18.3337 13.5V6.5C18.3337 5.09987 18.3337 4.3998 18.0612 3.86502C17.8215 3.39462 17.439 3.01217 16.9686 2.77248C16.4339 2.5 15.7338 2.5 14.3337 2.5L5.66699 2.5C4.26686 2.5 3.5668 2.5 3.03202 2.77248C2.56161 3.01217 2.17916 3.39462 1.93948 3.86502C1.66699 4.3998 1.66699 5.09987 1.66699 6.5Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
