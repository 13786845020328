import { Box, SvgIconProps } from '@mui/material'
import { env } from 'app/env'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import {
  IconBriefcase01,
  IconChartBreakoutCircle,
  IconCheckVerified01,
  IconLayoutAlt04,
  IconNotificationText,
  IconSettings01,
  IconStart07,
  IconUsers02,
} from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { Link, Tab, Tabs } from 'ui/navigation'

import { MenuDrawer } from './menu-drawer'

export type NavigationItem = {
  label: string
  path: string
  Icon: (props: SvgIconProps) => JSX.Element
}

export const MenuNavigation = ({
  isOpen,
  toggle,
}: {
  isOpen: boolean
  toggle: () => void
}) => {
  const { t } = useTranslation()
  const {
    canGetRecruiterPerformanceReport,
    canViewContacts,
    canViewJobs,
    canViewJobRequests,
    canViewActivities,
    canViewMyRecruiterDashboard,
    canViewMyRecruiterPerformance,
    canViewDashboardTeam,
    canViewTalentsPool,
  } = useScopes()

  const navigationItems: Array<NavigationItem> = [
    canViewDashboardTeam
      ? {
          label: t('common.dashboard'),
          path: paths.teamsDashboard,
          Icon: IconLayoutAlt04,
        }
      : undefined,
    canViewMyRecruiterDashboard
      ? {
          label: t('common.my_dashboard'),
          path: paths.recruiterDashboard,
          Icon: IconLayoutAlt04,
        }
      : undefined,
    canViewContacts
      ? {
          label: t('common.contacts'),
          path: paths.contacts,
          Icon: IconUsers02,
        }
      : undefined,
    canViewTalentsPool
      ? {
          label: t('common.talent_pools'),
          path: paths.talentsPools,
          Icon: IconStart07,
        }
      : undefined,
    canViewJobs
      ? {
          label: t('common.jobs'),
          path: paths.jobs,
          Icon: IconBriefcase01,
        }
      : undefined,
    canViewJobRequests
      ? {
          label: t('common.requests'),
          path: paths.jobRequests,
          Icon: IconNotificationText,
        }
      : undefined,
    canViewActivities
      ? {
          label: t('common.activities'),
          path: paths.activities,
          Icon: IconCheckVerified01,
        }
      : undefined,
    canGetRecruiterPerformanceReport || canViewMyRecruiterPerformance
      ? {
          label: t('common.reports'),
          path: canViewMyRecruiterPerformance
            ? paths.myRecruiterPerformanceReport
            : paths.reports,
          Icon: IconChartBreakoutCircle,
        }
      : undefined,
    env.REACT_APP_ENV === 'development' &&
    window.location.hostname === 'localhost'
      ? {
          label: 'Icons',
          path: paths.iconsList,
          Icon: IconSettings01,
        }
      : undefined,
  ].filter(item => item !== undefined) as NavigationItem[]

  return (
    <Box>
      <Box
        sx={{
          display: {
            xl: 'flex',
            lg: 'flex',
            md: 'flex',
            sm: 'none',
            xs: 'none',
          },
          paddingX: '8px',
        }}
      >
        <Tabs value={`/${location.pathname.split('/')[1]}`}>
          {navigationItems.map(navigation => (
            <Tab
              component={Link}
              enableHover
              key={`menu-${navigation.path}`}
              icon={<navigation.Icon sx={{ fontSize: 15 }} />}
              iconPosition="start"
              to={navigation.path}
              value={navigation.path}
              label={navigation.label}
            />
          ))}
        </Tabs>
      </Box>
      <MenuDrawer
        isOpen={isOpen}
        toggle={toggle}
        navigationItems={navigationItems}
      />
    </Box>
  )
}
