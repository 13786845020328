import { alpha, createTheme, darken, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useQuery } from '@tanstack/react-query'
import { CareerSiteConfig, getCareerSiteConfigByCompanySlug, qk } from 'api'
import { theme } from 'app/mui-theme'
import enLocale from 'date-fns/locale/en-US'
import { componentColors } from 'pages/settings/career-site/component-color-select'
import { cornerStyles } from 'pages/settings/career-site/corner-style-select'
import { ReactNode } from 'react'
import { createContext, useContext } from 'react'

type CareerSiteConfigContextValue = {
  colors: {
    primaryColor: string
    tagColor: string
    backgroundColor: string
    hoverFocusColor: string
  }
  fontFamily: string
  borderRadius: string
  config: CareerSiteConfig
}

const CareerSiteConfigContext =
  createContext<CareerSiteConfigContextValue | null>(null)

export const useCareerSiteConfigContext = () => {
  const context = useContext(CareerSiteConfigContext)
  if (!context) {
    throw new Error(
      'useCareerSiteConfig must be used within a CareerSiteProvider',
    )
  }

  return context
}

type Props = Readonly<{
  companySlug: string
  children: ReactNode
}>

export const CareerSiteProvider = ({ companySlug, children }: Props) => {
  const $getCareerSiteConfig = useQuery(
    qk.careerSite.configurationBySlug.toKeyWithArgs({ slugId: companySlug }),
    () => getCareerSiteConfigByCompanySlug({ slugId: companySlug }),
    { keepPreviousData: true, refetchOnWindowFocus: true },
  )

  if (!$getCareerSiteConfig.data) return null

  const { fontStyle, componentColor, vacanciesDisplaying, cornerStyle } =
    $getCareerSiteConfig.data

  const fontFamilies = {
    HELVETICA: 'Helvetica, sans-serif',
    ARIAL: 'Arial, sans-serif',
    ARIAL_BLACK: '"Arial Black", sans-serif',
    VERDANA: 'Verdana, sans-serif',
    TAHOMA: 'Tahoma, sans-serif',
    TREBUCHET_MS: '"Trebuchet MS", sans-serif',
    IMPACT: 'Impact, sans-serif',
    GILL_SANS: '"Gill Sans", sans-serif',
    TIMES_NEW_ROMAN: '"Times New Roman", serif',
    GEORGIA: 'Georgia, serif',
    PALATINO: 'Palatino, serif',
    BASKERVILLE: 'Baskerville, serif',
    ANDALE_MONO: '"Andale Mono", monospace',
    COURIER: 'Courier, monospace',
    LUCIDA: 'Lucida, monospace',
    MONACO: 'Monaco, monospace',
    BRADLEY_HAND: '"Bradley Hand", cursive',
    BRUSH_SCRIPT_MT: '"Brush Script MT", cursive',
    COMIC_SANS_MS: '"Comic Sans MS", cursive',
    LUMINARI: 'Luminari, fantasy',
  }

  const primaryFontFamily = fontFamilies[fontStyle] || 'Helvetica, sans-serif'

  const primaryColor =
    componentColors.find(({ code }) => code === componentColor)?.color ??
    theme.palette.primary.main

  const tagColor = alpha(primaryColor, 0.1)
  const backgroundColor = alpha(primaryColor, 0.03)
  const hoverFocusColor = darken(primaryColor, 0.1)

  const cardBorderRadius =
    cornerStyle === 'CIRCULAR'
      ? '32px'
      : cornerStyles.find(({ cornerStyle: style }) => style === cornerStyle)
          ?.borderRadius ?? '0px'

  const borderRadius =
    vacanciesDisplaying === 'CARDS' ? cardBorderRadius : '0px'

  const careerSiteTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        main: primaryColor,
      },
    },
    typography: {
      ...theme.typography,
      fontFamily: primaryFontFamily,
      h1: {
        ...theme.typography.h1,
        fontFamily: primaryFontFamily,
      },
      h2: {
        ...theme.typography.h2,
        fontFamily: primaryFontFamily,
      },
      h3: {
        ...theme.typography.h3,
        fontFamily: primaryFontFamily,
      },
      h4: {
        ...theme.typography.h4,
        fontFamily: primaryFontFamily,
      },
      h5: {
        ...theme.typography.h5,
        fontFamily: primaryFontFamily,
      },
      h6: {
        ...theme.typography.h6,
        fontFamily: primaryFontFamily,
      },
      body1: {
        ...theme.typography.body1,
        fontFamily: primaryFontFamily,
      },
      body2: {
        ...theme.typography.body2,
        fontFamily: primaryFontFamily,
      },
      subtitle1: {
        ...theme.typography.subtitle1,
        fontFamily: primaryFontFamily,
      },
      subtitle2: {
        ...theme.typography.subtitle2,
        fontFamily: primaryFontFamily,
      },
      caption: {
        ...theme.typography.caption,
        fontFamily: primaryFontFamily,
      },
      overline: {
        ...theme.typography.overline,
        fontFamily: primaryFontFamily,
      },
      button: {
        ...theme.typography.button,
        fontFamily: primaryFontFamily,
      },
    },
    components: {
      MuiButton: {
        ...theme.components?.MuiButton,
        styleOverrides: {
          ...theme.components?.MuiButton?.styleOverrides,
          root: {
            borderRadius: borderRadius,
          },
        },
        variants: [
          ...(theme.components?.MuiButton?.variants ?? []),
          {
            props: { variant: 'contained' },
            style: {
              color: '#FFFFFF',
              background: primaryColor,
              '&:hover, &:focus, &:active': {
                background: hoverFocusColor,
              },
            },
          },
        ],
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            '.MuiTabs-flexContainer': {
              gap: '16px',
            },
          },
          indicator: {
            display: 'none',
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontSize: '15px',
            lineHeight: '18.15px',
            fontWeight: 500,
            color: '#000000',
            textTransform: 'initial',
            textAlign: 'center',
            borderRadius: '12px',

            '&:hover, &:focus, &.Mui-selected': {
              fontWeight: 600,
              color: '#000000',
            },

            a: {
              color: 'inherit',
            },
          },
        },
      },
    },
  })

  const contextValue: CareerSiteConfigContextValue = {
    colors: {
      primaryColor,
      tagColor,
      backgroundColor,
      hoverFocusColor,
    },
    fontFamily: primaryFontFamily,
    borderRadius: borderRadius,
    config: $getCareerSiteConfig.data,
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enLocale}>
      <CareerSiteConfigContext.Provider value={contextValue}>
        <ThemeProvider theme={careerSiteTheme}>{children}</ThemeProvider>
      </CareerSiteConfigContext.Provider>
    </LocalizationProvider>
  )
}
