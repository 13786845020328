import {
  Box,
  InputAdornment,
  Paper,
  Stack,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getCompanyLanguages, qk } from 'api'
import { IconX } from 'assets/icons'
import { IconDragger, IconSearchLg } from 'assets/icons'
import { Fieldset } from 'components/common'
import { Droppable } from 'components/common/droppable'
import { useDictionaries } from 'lib/app-helpers'
import { useCallback, useEffect, useState } from 'react'
import { DragDropContext, Draggable, DropResult } from 'react-beautiful-dnd'
import { Control, Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TableCell, TableRow } from 'ui/data'
import { Autocomplete } from 'ui/inputs/autocomplete'
import { IconButton } from 'ui/inputs/icon-button'
import { renderSelect } from 'ui/inputs/select'
import { TruncateWithTooltip } from 'ui/utils'

type Languages = Array<{
  languageId: string
  name: string
  level: string
  order: number
}>

type Props = {
  control: unknown
  legend: string
  hint: string
  selectedLanguages: Languages
}

export const LanguagesWithLevels = (props: Props) => {
  const { t } = useTranslation()
  const control = props.control as Control<{ languages: Languages }>

  const $languages = useQuery(qk.company.languages.toKey(), getCompanyLanguages)
  const { dictionaries, isDictionariesError } = useDictionaries()

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'languages',
    keyName: 'itemId',
  })

  const excludeLanguages = new Set(
    props.selectedLanguages.map(language => language.languageId),
  )

  const firstLevel = dictionaries.languageLevels.data[0]

  const onDragEnd = useCallback(
    ({ source, destination }: DropResult) => {
      if (!destination) return
      move(source.index, destination.index)
    },
    [move],
  )

  const [value, setValue] = useState('')
  useEffect(() => {
    setValue('')
  }, [fields.length])

  return (
    <Fieldset legend={props.legend} hint={props.hint}>
      <Box>
        <Autocomplete
          value={value ?? ''}
          onChange={(_id, option) => {
            if (option) {
              setValue(option.value)
              append({
                languageId: option.value,
                name: option.label,
                level: firstLevel?.code ?? '',
                order: props.selectedLanguages.length + 1,
              })
            }
          }}
          dataCy="languagesAutocompleteInput"
          placeholder={t('common.search_language')}
          loading={$languages.isLoading}
          loadingError={$languages.isError}
          // helperText={<LanguagesConfigurationHint variant="full" />}
          options={
            $languages.data
              ?.filter(language => !excludeLanguages.has(language.id))
              .map(language => ({
                value: language.id,
                label: language.name,
              })) ?? []
          }
          startAdornment={
            <InputAdornment position="start">
              <IconSearchLg sx={{ fontSize: '16px' }} />
            </InputAdornment>
          }
        />

        {fields.length > 0 && (
          <Box mt={2}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-languages">
                {provided => (
                  <TableContainer
                    component={Paper}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <Table>
                      <TableBody>
                        {fields.map((field, index) => (
                          <Draggable
                            key={field.languageId}
                            draggableId={field.languageId}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <TableRow
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                sx={{
                                  cursor:
                                    fields.length > 0 ? 'grab' : undefined,
                                  ...(snapshot.isDragging && {
                                    border: '1px solid',
                                    borderColor: 'divider',
                                    bgcolor: '#FFFFFF',
                                  }),
                                }}
                              >
                                <TableCell width="100%">
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <IconDragger
                                      sx={{
                                        fontSize: '20px',
                                      }}
                                    />

                                    <TruncateWithTooltip
                                      variant="body1"
                                      maxWidth="162px"
                                    >
                                      {field.name}
                                    </TruncateWithTooltip>
                                  </Stack>
                                </TableCell>
                                <TableCell sx={{ minWidth: '240px' }}>
                                  <Controller
                                    key="level"
                                    control={control}
                                    name={
                                      `languages.${index as 0}.level` as const
                                    }
                                    defaultValue={field.level}
                                    render={renderSelect({
                                      disableAlphabeticalOrder: true,
                                      disableEmptyOption: true,
                                      fontSize: '14px',
                                      loadingError: isDictionariesError,
                                      options:
                                        dictionaries.languageLevels.options,
                                      dataCy: `languageLevelSelect${index}`,
                                    })}
                                  />
                                </TableCell>
                                <TableCell width="40px">
                                  <IconButton
                                    key="action"
                                    onClick={() => {
                                      remove(index)
                                    }}
                                    data-cy={`deleteLanguageBtn${index}`}
                                  >
                                    <IconX
                                      sx={{
                                        fontSize: '20px',
                                        color: 'error.main',
                                      }}
                                    />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        )}
      </Box>
    </Fieldset>
  )
}
