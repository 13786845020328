import { addMinutes, format, isValid, parse, startOfDay } from 'date-fns'
import {
  convertTimeFormat,
  getTimeFormatPlaceholder,
  useDateAndTimeFormat,
} from 'lib/context'
import { useCallback, useMemo, useState } from 'react'

import { Autocomplete, AutocompleteProps } from './autocomplete'

type Props = Omit<
  AutocompleteProps,
  'value' | 'onChange' | 'options' | 'renderInput'
> & {
  value: Date | null
  onChange: (date: Date | null) => void
  minutesStep: number
  startAfter?: Date
  date?: Date | null
}

/** Allows to choose predefined time values or to enter it manually */
export const TimePicker = ({
  value,
  minutesStep,
  startAfter,
  onChange,
  date,
  ...rest
}: Props) => {
  const { timeFormat } = useDateAndTimeFormat()

  const localFormatTime = useCallback(
    (d: Date) => {
      return format(d, convertTimeFormat(timeFormat))
    },
    [timeFormat],
  )

  const [inputValue, setInputValue] = useState(() => {
    return value !== null && isValid(value) ? localFormatTime(value) : ''
  })

  const options = useMemo(() => {
    const minutesInDay = 24 * 60
    const count = minutesInDay / minutesStep
    const baseline = startOfDay(date ?? new Date())

    const times: Array<{ value: string; label: string }> = []

    for (let index = 0; index < count; index++) {
      const time = addMinutes(baseline, index * minutesStep)
      const formattedTime = localFormatTime(time)
      times.push({
        value: formattedTime,
        label: formattedTime,
      })
    }

    if (startAfter) {
      return times.filter(time => {
        const timeDate = parse(
          time.value,
          convertTimeFormat(timeFormat),
          baseline,
        )
        return isValid(timeDate) && timeDate > startAfter
      })
    }

    return times
  }, [date, localFormatTime, minutesStep, startAfter, timeFormat])

  return (
    <Autocomplete
      value={inputValue}
      doStartWithFilter
      onChange={newValue => {
        setInputValue(newValue)
        if (newValue === '') {
          onChange(null)
        } else {
          onChange(
            parse(newValue, convertTimeFormat(timeFormat), date ?? new Date()),
          )
        }
      }}
      options={options}
      placeholder={getTimeFormatPlaceholder()}
      disableAlphabeticalOrder
      {...rest}
    />
  )
}
