import {
  Box,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getFile, qk } from 'api'
import { useScopes } from 'app/scopes'
import { IconArrowCircleDown, IconFile06 } from 'assets/icons'
import { saveAs } from 'file-saver'
import { useDateAndTimeFormat } from 'lib/context'
import { useBoolean } from 'lib/react-utils'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentPreviewDialog } from './document-preview-dialog'

const PDF_FILE_TYPE = 'application/pdf'

type Props = {
  fileId: string
  fileName: string
  fileCreatedAt?: Date
  showDate?: boolean
  withBorder?: boolean
  withSaveButton?: boolean
}

export const Document = ({
  showDate = true,
  withBorder = true,
  withSaveButton = false,
  fileId,
  fileName,
  fileCreatedAt,
}: Props) => {
  const dialogOpen = useBoolean()
  const { t } = useTranslation()
  const { canViewFile } = useScopes()

  const { data } = useQuery(qk.files.file.toKeyWithArgs({ fileId }), () =>
    getFile({ fileId }),
  )

  const { formatDate } = useDateAndTimeFormat()

  const url = data ? URL.createObjectURL(data) : undefined

  useEffect(() => {
    return () => {
      if (url) {
        URL.revokeObjectURL(url)
      }
    }
  }, [url])

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Tooltip
          title={
            data && data.type === PDF_FILE_TYPE
              ? t('common.open_preview')
              : t('common.download')
          }
          arrow
          placement="top"
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignContent="center"
            width="fit-content"
            maxWidth="100%"
            sx={{
              paddingY: 0.5,
              ...(withBorder && {
                border: theme => `1px solid ${theme.palette.mineShaft[100]}`,
                borderRadius: '5px',
                padding: 2,
              }),

              cursor: 'pointer',
            }}
            onClick={() => {
              if (url) {
                const anchor = document.createElement('a')
                anchor.href = url
                anchor.download = fileName
                anchor.click()
                URL.revokeObjectURL(url)
              }
            }}
            {...(data &&
              canViewFile &&
              data.type === PDF_FILE_TYPE && {
                onClick: dialogOpen.setTrue,
              })}
          >
            <IconFile06 color="primary" />

            <Stack direction="row" alignItems="center" spacing={2} ml={1}>
              {url ? (
                <>
                  <Typography
                    maxWidth={400}
                    variant="body2"
                    sx={{ wordWrap: 'break-word' }}
                  >
                    {fileName}
                  </Typography>
                  {fileCreatedAt && showDate && (
                    <Typography variant="body2">
                      {formatDate(fileCreatedAt)}
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Skeleton width={75} sx={{ mx: 1 }} />{' '}
                  <Skeleton width={25} sx={{ mx: 1 }} />
                </>
              )}
            </Stack>
          </Stack>
        </Tooltip>

        {withSaveButton && data && (
          <Tooltip title={t('common.download')}>
            <IconButton
              onClick={() => saveAs(data, fileName)}
              sx={{ width: '22px', height: '22px' }}
            >
              <IconArrowCircleDown
                sx={{ fontSize: '20px', color: '#1A1A1A' }}
              />
            </IconButton>
          </Tooltip>
        )}
      </Stack>

      {url && data && (
        <DocumentPreviewDialog
          isOpen={dialogOpen.isTrue}
          onClose={dialogOpen.setFalse}
          url={url}
          name={fileName}
        />
      )}
    </Box>
  )
}
