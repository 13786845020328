import {
  Box,
  darken,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getContactDetails, qk } from 'api'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import {
  IconCalendarPlus02,
  IconChevronLeft,
  IconChevronRight,
  IconStart07,
  IconUserArrowRight,
  IconXCircle,
  IconZapCircle,
} from 'assets/icons'
import { IconVideoRecorder } from 'assets/icons/icons-v2/general/video-recorder'
import { Document } from 'components/common'
import {
  AddContactsToTalentsPoolForm,
  ContactJobPipelineStep,
  ContactLink,
  InviteToInterviewButton,
} from 'components/contacts'
import { skillLevelIndicators } from 'components/skills/skill-badge'
import { ErrorTemplateSmall } from 'components/templates'
import { useFormatLocation } from 'lib/app-helpers'
import { renderQueryResult } from 'lib/react-query-utils'
import { useBoolean } from 'lib/react-utils'
import { CreateActivityDialog } from 'pages/activities/create'
import { SocialContactItems } from 'pages/contacts/profile/details/_sections'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { Avatar } from 'ui/data'
import { Dialog } from 'ui/feedback'
import { Button } from 'ui/inputs/button'
import { IconButton } from 'ui/inputs/icon-button'
import { Link } from 'ui/navigation'
import { TruncateWithTooltip } from 'ui/utils'

export type Props = {
  contactId: string
  interviewPositionName?: string
  jobId?: string
  onOpenDialog?: () => void
  onCloseDialog?: () => void
  onClose?: () => void
  contactIds?: Array<string>
  setPreviewId?: (previewId: string) => void
}

export const ContactPreview = (props: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const formatLocation = useFormatLocation()

  const { canManageActivity, canManageTalentsPool } = useScopes()

  const isNewActivityOpen = useBoolean()
  const showAddToTalentPoolDialog = useBoolean()

  const $contactDetails = useQuery(
    qk.contacts.details.toKeyWithArgs({ contactId: props.contactId }),
    () => getContactDetails({ contactId: props.contactId }),
  )

  return (
    <>
      {renderQueryResult($contactDetails, {
        error: error => <ErrorTemplateSmall error={error} />,
        success: contactDetails => {
          return (
            <Box display="flex" flexDirection="column" height="100%">
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                sx={{
                  padding: '9.5px 16px',
                  borderBottom: theme => `1px solid ${theme.palette.divider}`,
                }}
              >
                <Box display="flex" alignItems="center">
                  <Typography variant="h4" mr={2}>
                    {t('common.contact_preview')}
                  </Typography>

                  {props.contactIds && props.setPreviewId && (
                    <ContactsNavigation
                      contactIds={props.contactIds}
                      contactId={props.contactId}
                      setPreviewId={props.setPreviewId}
                    />
                  )}
                </Box>

                <Stack direction="row" spacing={0.5}>
                  <Tooltip title={t('common.view_details')}>
                    <IconButton
                      onClick={() => {
                        navigate(
                          generatePath(paths.contactDetails, {
                            contactId: props.contactId,
                          }),
                        )
                      }}
                    >
                      <IconUserArrowRight
                        sx={{
                          fontSize: '20px',
                          color: theme.palette.mineShaft[900],
                        }}
                      />
                    </IconButton>
                  </Tooltip>

                  <IconButton onClick={props.onClose}>
                    <IconXCircle
                      sx={{ fontSize: '20px', color: 'mineShaft.main' }}
                    />
                  </IconButton>
                </Stack>
              </Stack>

              <Stack padding="16px" spacing={3}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  spacing={2}
                >
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Avatar size={60} src={contactDetails.imageUri} />

                    <Stack spacing={0.5}>
                      <TruncateWithTooltip variant="h3" maxWidth={200}>
                        <Link
                          to={generatePath(paths.contactDetails, {
                            contactId: props.contactId,
                          })}
                          maxWidth={200}
                        >
                          {contactDetails.name}
                        </Link>
                      </TruncateWithTooltip>

                      <Typography color="text.disabled">
                        {contactDetails.position.name} |{' '}
                        {contactDetails.qualificationLevel?.name}
                      </Typography>
                    </Stack>
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap={1}
                    flexWrap="wrap"
                  >
                    {canManageActivity && (
                      <>
                        <Tooltip title={t('common.new_activity')}>
                          <IconButton
                            onClick={() => {
                              isNewActivityOpen.setTrue()
                              if (props.onOpenDialog) props.onOpenDialog()
                            }}
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              padding: '8px',
                              width: '40px',
                              height: '40px',
                            }}
                          >
                            <IconCalendarPlus02
                              sx={{ fontSize: '24px', color: 'mineShaft.main' }}
                            />
                          </IconButton>
                        </Tooltip>

                        <CreateActivityDialog
                          isOpen={isNewActivityOpen.isTrue}
                          onClose={() => {
                            isNewActivityOpen.setFalse()
                            if (props.onCloseDialog) props.onCloseDialog()
                          }}
                          contactId={props.contactId}
                        />
                      </>
                    )}

                    <InviteToInterviewButton
                      candidateId={contactDetails.candidateProfileId}
                      positionName={props.interviewPositionName}
                      isContactHasEmail={
                        !!contactDetails?.contactInfos.some(
                          ({ type }) => type === 'EMAIL',
                        )
                      }
                      jobId={props.jobId}
                      renderButton={({
                        isDisabled,
                        disabledTooltipTitle,
                        onClick,
                      }) => {
                        return (
                          <Tooltip
                            title={
                              isDisabled
                                ? disabledTooltipTitle
                                : t('common.schedule_interview')
                            }
                          >
                            <Box>
                              <IconButton
                                onClick={onClick}
                                disabled={isDisabled}
                                sx={{
                                  border: `1px solid ${theme.palette.divider}`,
                                  padding: '8px',
                                  width: '40px',
                                  height: '40px',
                                }}
                              >
                                <IconVideoRecorder
                                  sx={{
                                    fontSize: '24px',
                                    color: 'mineShaft.main',
                                  }}
                                />
                              </IconButton>
                            </Box>
                          </Tooltip>
                        )
                      }}
                    />

                    {canManageTalentsPool && (
                      <>
                        <Tooltip title={t('common.add_to_talent_pool')}>
                          <IconButton
                            onClick={() => {
                              showAddToTalentPoolDialog.setTrue()
                              if (props.onOpenDialog) props.onOpenDialog()
                            }}
                            sx={{
                              border: `1px solid ${theme.palette.divider}`,
                              padding: '8px',
                              background: theme.palette.mediumPurple.main,
                              width: '40px',
                              height: '40px',
                              '&:hover': {
                                background: darken(
                                  theme.palette.mediumPurple.main,
                                  0.1,
                                ),
                              },
                            }}
                          >
                            <IconStart07
                              sx={{ fontSize: '24px', color: 'white' }}
                            />
                          </IconButton>
                        </Tooltip>

                        <Dialog
                          size="small"
                          open={showAddToTalentPoolDialog.isTrue}
                          onClose={() => {
                            showAddToTalentPoolDialog.setFalse()
                            props.onCloseDialog?.()
                          }}
                        >
                          <AddContactsToTalentsPoolForm
                            contactsIds={[props.contactId]}
                            onClose={() => {
                              showAddToTalentPoolDialog.setFalse()
                              props.onCloseDialog?.()
                            }}
                          />
                        </Dialog>
                      </>
                    )}
                  </Stack>
                </Stack>

                {contactDetails.skills && contactDetails.skills.length > 0 && (
                  <Stack direction="row" flexWrap="wrap" gap={1}>
                    {contactDetails.skills.map(skill => {
                      const detectedSkill =
                        skillLevelIndicators[skill.skillLevelCode]

                      return (
                        <Tooltip
                          arrow
                          key={skill.skillId}
                          placement="top-start"
                          title={
                            <Typography variant="caption" sx={{ p: 0.5 }}>
                              {t(detectedSkill.title, {
                                skillName: skill.skillName,
                              }).toString()}
                            </Typography>
                          }
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            bgcolor={detectedSkill.bgColor}
                            padding="4px 16px 4px 4px"
                            borderRadius="62px"
                          >
                            <Box
                              mr="8px"
                              bgcolor="#FFFFFF"
                              width="24px"
                              height="24px"
                              borderRadius="50%"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              {detectedSkill.icon}
                            </Box>
                            {skill.skillName}
                          </Box>
                        </Tooltip>
                      )
                    })}
                  </Stack>
                )}

                {contactDetails.contactInfos.length > 0 && (
                  <Stack direction="row" flexWrap="wrap" gap={2}>
                    <SocialContactItems
                      contactInfos={contactDetails.contactInfos}
                      withCopyButton={true}
                      displayInRow={true}
                    />
                  </Stack>
                )}

                {contactDetails.note && (
                  <Box
                    display="flex"
                    bgcolor="#D3D3D31A"
                    padding="12px 12px 8px 12px"
                    borderRadius="8px"
                    alignItems="center"
                  >
                    <Box mr="16px" alignSelf="flex-start">
                      <IconZapCircle />
                    </Box>

                    <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: contactDetails.note }}
                      sx={{
                        '& p': { margin: 0 },
                      }}
                    />
                  </Box>
                )}

                {contactDetails?.jobApplicationResponses?.map(
                  contactJobPipelineStep => {
                    return (
                      <ContactJobPipelineStep
                        key={contactJobPipelineStep.jobId}
                        jobPipelineDetails={{
                          jobId: contactJobPipelineStep.jobId,
                          jobName: contactJobPipelineStep.jobName,
                          location: formatLocation(
                            contactJobPipelineStep.addressResponse?.country
                              ?.code,
                            contactJobPipelineStep.addressResponse?.city,
                          ),
                          pipelineStepName:
                            contactJobPipelineStep.pipelineStepName,
                          pipelineStepValue:
                            contactJobPipelineStep.completedPipeline,
                          priority: contactJobPipelineStep.jobPriority,
                          jobTypeName: contactJobPipelineStep.jobType,
                        }}
                      />
                    )
                  },
                )}

                {contactDetails.documents &&
                  contactDetails.documents.length > 0 && (
                    <Box>
                      <Typography variant="h4" mb={2}>
                        {t('common.documents')}
                      </Typography>

                      <Stack spacing={1}>
                        {contactDetails.documents.map(document => (
                          <Document
                            key={document.fileId}
                            fileId={document.fileId}
                            fileName={document.name}
                            fileCreatedAt={document.createdAt}
                            showDate={false}
                            withBorder={false}
                            withSaveButton={true}
                          />
                        ))}
                      </Stack>
                    </Box>
                  )}

                {contactDetails.linkResponses &&
                  contactDetails.linkResponses.length > 0 && (
                    <Box>
                      <Typography variant="h4" mb={2}>
                        {t('common.links')}
                      </Typography>

                      <Stack spacing={2}>
                        {contactDetails.linkResponses.map(
                          ({ link, favicon }) => (
                            <ContactLink
                              key={link}
                              linkHref={link}
                              faviconBase64Data={favicon}
                              withCopyButton={true}
                            />
                          ),
                        )}
                      </Stack>
                    </Box>
                  )}
              </Stack>
            </Box>
          )
        },
      })}
    </>
  )
}

type ContactsNavigationProps = Readonly<{
  contactIds: Array<string>
  contactId: string
  setPreviewId: (contactId: string) => void
}>

const ContactsNavigation = ({
  contactIds,
  contactId,
  setPreviewId,
}: ContactsNavigationProps) => {
  const { t } = useTranslation()

  if (contactIds.length === 0) {
    return null
  }

  const currentContactIndex = contactIds.indexOf(contactId)

  const prevContactIndex = contactIds[currentContactIndex - 1]
  const nextContactIndex = contactIds[currentContactIndex + 1]

  return (
    <Stack
      direction="row"
      spacing={1}
      color={theme =>
        prevContactIndex ? theme.palette.mineShaft[800] : 'inherit'
      }
    >
      <Button
        startIcon={
          <IconChevronLeft
            sx={{
              fontSize: '14px !important',
            }}
          />
        }
        sx={{ padding: '4px 8px', color: 'inherit' }}
        variant="text"
        disabled={!prevContactIndex}
        onClick={() => setPreviewId(prevContactIndex!)}
      >
        {t('common.previous')}
      </Button>

      <Button
        endIcon={
          <IconChevronRight
            sx={{
              fontSize: '14px !important',
            }}
          />
        }
        sx={{ padding: '4px 8px', color: 'inherit' }}
        variant="text"
        disabled={!nextContactIndex}
        onClick={() => setPreviewId(nextContactIndex!)}
      >
        {t('common.next')}
      </Button>
    </Stack>
  )
}
