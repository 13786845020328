import { Tooltip } from '@mui/material'
import { IconMenu01 } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

export const MenuOpen = ({
  toggle,
}: {
  isOpen: boolean
  toggle: () => void
}) => {
  const { t } = useTranslation()

  return (
    <Tooltip title={t('common.open')} placement="bottom">
      <IconButton
        onClick={toggle}
        sx={{
          display: { md: 'none', xs: 'flex' },
        }}
      >
        <IconMenu01
          stroke="2"
          sx={{
            fontSize: '22px',
            color: theme => theme.palette.mineShaft.main,
          }}
        />
      </IconButton>
    </Tooltip>
  )
}
