import { Box, Paper, Stack, Typography } from '@mui/material'
import { JobPriority } from 'api'
import { paths } from 'app/paths'
import { jobPriorities } from 'lib/records'
import { generatePath } from 'react-router'
import { CircularProgress } from 'ui/feedback'
import { Link } from 'ui/navigation'

type Props = Readonly<{
  jobPipelineDetails: {
    jobId: string
    priority: JobPriority
    jobName: string
    pipelineStepName: string
    pipelineStepValue: number
    location: string
    jobTypeName: string
  }
}>

export const ContactJobPipelineStep = ({ jobPipelineDetails }: Props) => {
  const { Icon: JobPriorityIcon } = jobPriorities[jobPipelineDetails.priority]

  return (
    <Link
      to={generatePath(paths.jobFlow, { id: jobPipelineDetails.jobId })}
      target="_blank"
    >
      <Paper sx={{ bgcolor: '#D3D3D31A', padding: '12px 8px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack direction="row" alignItems="center" spacing={2} flex="0 1 50%">
            <JobPriorityIcon sx={{ fontSize: '16px' }} />

            <Box>
              <Typography variant="body1">
                {jobPipelineDetails.jobName}
              </Typography>
              <Typography color="mineShaft.800" variant="body2">
                {jobPipelineDetails.location} • {jobPipelineDetails.jobTypeName}
              </Typography>
            </Box>
          </Stack>

          <Box flex="0 1 50%" display="flex" justifyContent="flex-end">
            <Stack direction="row" alignItems="center" spacing="6px">
              <CircularProgress
                variant="determinate"
                value={jobPipelineDetails.pipelineStepValue}
                color={getCircularProgressColor(
                  jobPipelineDetails.pipelineStepValue,
                )}
                size="16px"
                thickness={6}
              />

              <Typography variant="body1">
                {jobPipelineDetails.pipelineStepName}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Paper>
    </Link>
  )
}

const getCircularProgressColor = (value: number) => {
  switch (true) {
    case value < 26: {
      return 'error'
    }
    case value < 51: {
      return 'warning'
    }
    case value < 76: {
      return 'info'
    }
    default: {
      return 'success'
    }
  }
}
