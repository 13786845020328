import './transitions.css'

import { Box, Tooltip } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { useIsFetching } from '@tanstack/react-query'
import {
  Notification,
  qk,
  readNotifications,
  unreadNotifications,
  useInvalidateNotifications,
} from 'api'
import { useScopes } from 'app/scopes'
import {
  IconCheckboxActive,
  IconCheckboxInactive,
} from 'assets/icons/icons-v2/checkboxes'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

type Props = {
  notification: Notification
  /** Adds smooth hiding on read */
  hideOnRead?: boolean
  iconSize?: number
}

export const NotificationStatusButton = ({
  notification,
  hideOnRead,
  iconSize = 20,
}: Props) => {
  const { t } = useTranslation()
  const invalidateNotifications = useInvalidateNotifications()
  const { canManageNotifications } = useScopes()

  const isHiding = useBoolean()

  const notificationsListFetching = useIsFetching(
    qk.notifications.list.toKey(),
    { exact: false },
  )

  const status = notification.read ? 'READ' : 'UNREAD'

  const $unreadNotifications = useMutation(unreadNotifications)
  const $readNotifications = useMutation(readNotifications)

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        notification.read
          ? t('common.mark_as_unread')
          : t('common.mark_as_read')
      }
    >
      <Box component="span">
        {status === 'READ' && canManageNotifications && isHiding.isFalse ? (
          <IconButton
            disabled={
              $unreadNotifications.isLoading || notificationsListFetching > 0
            }
            onClick={() => {
              $unreadNotifications.mutate(
                { notificationIds: [notification.notificationId] },
                { onSuccess: invalidateNotifications },
              )
            }}
            sx={{
              height: 20,
              width: 20,
            }}
          >
            <IconCheckboxActive sx={{ fontSize: iconSize }} />
          </IconButton>
        ) : (
          <IconButton
            sx={{
              height: 20,
              width: 20,
              ...(isHiding.isTrue && {
                animationName: 'hide-notification-status',
                animationDuration: '300ms',
                animationFillMode: 'forwards',
                animationTimingFunction: 'cubic-bezier(0.7, -0.4, 0.4, 1.4)',
              }),
            }}
            disabled={
              $readNotifications.isLoading || notificationsListFetching > 0
            }
            onClick={() => {
              $readNotifications.mutate(
                { notificationIds: [notification.notificationId] },
                {
                  onSuccess: () => {
                    invalidateNotifications()
                    if (hideOnRead) {
                      isHiding.setTrue()
                    }
                  },
                },
              )
            }}
          >
            <IconCheckboxInactive sx={{ fontSize: iconSize }} />
          </IconButton>
        )}
      </Box>
    </Tooltip>
  )
}
