import { ChangeEvent } from 'react'
import { ControllerRenderProps, FieldValues, Path } from 'react-hook-form'

export const transformNumberField = <V extends FieldValues, P extends Path<V>>(
  field: ControllerRenderProps<V, P>,
  minNumber?: number,
) => {
  return {
    ...field,
    value:
      field.value === undefined ||
      Number.isNaN(field.value) ||
      field.value === null
        ? ''
        : (field.value as number).toString(),
    onChange: (
      arg: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const normalizedValue = (
        typeof arg === 'string' ? arg : arg.target.value
      ).replace(',', '.')

      const [integerPart, decimalPart] = normalizedValue.split('.')

      if (integerPart && integerPart.length > 12) {
        return
      }

      if (decimalPart && decimalPart.length > 2) {
        return
      }

      const output = Number.parseFloat(normalizedValue)

      if (Number.isNaN(output)) {
        field.onChange(null)
      } else if (minNumber !== undefined && output < minNumber) {
        return
      } else {
        field.onChange(Number.parseFloat(output.toFixed(2)))
      }
    },
  }
}
