import { TCompanyPosition } from 'api/company'
import {
  TContactDocument,
  TContactInformation,
  TContactLink,
} from 'api/contacts'
import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

import { ScreeningQuestionAnswerTypeCodec } from '../jobs.codecs'

const TJobHiredContactInfo = t.strict({
  hiredAt: DateFromISOString,
  contactInfos: t.array(TContactInformation),
})

const TJobContactScreeningQuestion = t.strict({
  questionText: t.string,
  answers: t.array(t.unknown),
  answerType: ScreeningQuestionAnswerTypeCodec,
})

export type JobContactScreeningQuestion = t.TypeOf<
  typeof TJobContactScreeningQuestion
>

const TJobContact = t.intersection([
  t.strict({
    candidateId: t.string,
    name: t.string,
    position: TCompanyPosition,
    jobPipelineStage: t.strict({
      stageId: t.string,
      order: t.number,
      canHire: t.boolean,
    }),
    appliedAt: DateFromISOString,
    documents: t.array(TContactDocument),
    links: t.array(TContactLink),
    selfRequested: t.boolean,
  }),
  t.partial({
    rejectedAt: DateFromISOString,
    hireInfo: TJobHiredContactInfo,
    jobSource: t.string,
    imageUri: t.string,
    lastComment: t.string,
    contactInfos: t.array(TContactInformation),
    screeningQuestions: t.array(TJobContactScreeningQuestion),
  }),
])

export type JobContact = t.TypeOf<typeof TJobContact>

export const TJobContactsList = t.array(TJobContact)
