import { Divider, IconButton, Stack } from '@mui/material'
import { IconChevronLeft, IconChevronRight } from 'assets/icons'

type ItemNavigationProps = Readonly<{
  itemIds: Array<string>
  itemId: string
  setPreviewId: (itemId: string) => void
}>

export const PreviewNavigation = ({
  itemIds,
  itemId,
  setPreviewId,
}: ItemNavigationProps) => {
  if (itemIds.length === 0) {
    return null
  }

  const currentItemIndex = itemIds.indexOf(itemId)

  const prevItemId = itemIds[currentItemIndex - 1] ?? null
  const nextItemId = itemIds[currentItemIndex + 1] ?? null

  return (
    <Stack
      direction="row"
      divider={
        <Divider
          orientation="vertical"
          sx={{ width: '1px', height: '24px', bgcolor: 'divider' }}
        />
      }
      bgcolor="background.paper"
      border={1}
      borderRadius="35px"
      borderColor="divider"
    >
      <IconButton
        sx={{
          borderTopRightRadius: '0px',
          borderBottomRightRadius: '0px',
          width: '24px',
          height: '24px',
        }}
        disabled={!prevItemId}
        onClick={() => prevItemId && setPreviewId(prevItemId)}
        aria-label="Chevron left"
      >
        <IconChevronLeft
          sx={{
            fontSize: '12px',
            color: !prevItemId ? 'text.disabled' : 'action.active',
          }}
        />
      </IconButton>

      <IconButton
        sx={{
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          width: '24px',
          height: '24px',
        }}
        disabled={!nextItemId}
        onClick={() => nextItemId && setPreviewId(nextItemId)}
        aria-label="Chevron right"
      >
        <IconChevronRight
          sx={{
            fontSize: '12px',
            color: !nextItemId ? 'text.disabled' : 'action.active',
          }}
        />
      </IconButton>
    </Stack>
  )
}
