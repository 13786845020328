import { pipe } from 'fp-ts/lib/function'
import { decodeJson, get, put } from 'lib/request'

import {
  CareerSiteConfigCodec,
  ComponentColor,
  CornerStyle,
  FontStyle,
  VacanciesDisplaying,
} from './career-site-config.codecs'

export const getCareerSiteConfig = async () => {
  return pipe(
    await get('api/careers/configurations/me', {}),
    decodeJson(CareerSiteConfigCodec),
  )
}

type UpdateCareerSiteConfigInput = {
  body: {
    cornerStyle: CornerStyle
    fontStyle: FontStyle
    vacanciesDisplaying: VacanciesDisplaying
    componentColor: ComponentColor
    activeCoverImage: boolean
    activeTitle: boolean
    activeLinks: boolean

    title?: string
    subtitle?: string
    coverImageId?: string
    companyLogoId?: string
    links: Array<{ name: string; linkValue: string }> | null
  }
}

export const updateCareerSiteConfig = async (
  input: UpdateCareerSiteConfigInput,
) => {
  return await put('api/careers/configurations/me', {
    body: { ...input.body, highlightHotVacancies: true, showFilters: true },
  })
}

export type GetCareerSiteConfigByCompanySlugInput = {
  slugId: string
}

export const getCareerSiteConfigByCompanySlug = async ({
  slugId,
}: GetCareerSiteConfigByCompanySlugInput) => {
  return pipe(
    await get('api/careers/companies/slug/:slugId/configurations', {
      params: { slugId },
    }),
    decodeJson(CareerSiteConfigCodec),
  )
}
