import { AppBar, Box, Collapse, Stack, Toolbar } from '@mui/material'
import { APP_LAYOUT_X_PADDING } from 'app/mui-theme/theme'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import logoImage from 'assets/images/logo.png'
import { useBoolean } from 'lib/react-utils'
import { useEffect } from 'react'
import { matchRoutes } from 'react-router'

import { GlobalSearch } from './global-search'
import { Help } from './help'
import { MailLink } from './mail-link'
import { MenuOpen } from './menu-open'
import { MyProfile } from './my-profile'
import { MenuNavigation } from './navigation'
import { Notifications } from './notifications'
import { Search } from './search'

const APP_BAR_HEIGHT = 64

export const TopMenu = () => {
  const { canViewCompanySettings, canReadNotifications } = useScopes()
  const isSearchOpen = useBoolean()
  const isDrawerMenuOpen = useBoolean()
  const showSearchResults = useBoolean()
  const creationForms = matchRoutes(
    [
      { path: paths.createContact },
      { path: paths.createJob },
      { path: paths.createJobRequest },
    ],
    location,
  )

  useEffect(() => {
    creationForms && isSearchOpen.setFalse()
  }, [creationForms, isSearchOpen])

  return (
    <Box>
      <AppBar
        sx={{
          bgcolor: '#FFFFFF',
          border: 'none',
          borderBottom: 1,
          borderColor: 'divider',
          borderRadius: 0,
          zIndex: theme => theme.zIndex.appBar,
          position: 'relative',
          color: 'text.primary',
          height: APP_BAR_HEIGHT,
          background: theme =>
            `linear-gradient(270deg, #D18FF3 0%, ${theme.palette.flushOrange[300]} 100%)`,
        }}
      >
        <Toolbar
          sx={{
            minHeight: { xs: '100%' },
            paddingX: APP_LAYOUT_X_PADDING,
            justifyContent: 'space-between',
          }}
        >
          <Stack direction="row" gap={2} alignItems="center">
            <Box
              component="img"
              src={logoImage}
              alt="logo"
              display="block"
              width={35}
            />

            <MenuOpen
              isOpen={isDrawerMenuOpen.isTrue}
              toggle={isDrawerMenuOpen.toggle}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <Collapse
              in={isSearchOpen.isTrue && !creationForms}
              orientation="horizontal"
            >
              <GlobalSearch
                isOpen={showSearchResults.isTrue}
                onOpen={showSearchResults.setTrue}
                onClose={showSearchResults.setFalse}
              />
            </Collapse>
            <Collapse in={isSearchOpen.isFalse} orientation="horizontal">
              <MenuNavigation
                isOpen={isDrawerMenuOpen.isTrue}
                toggle={isDrawerMenuOpen.toggle}
              />
            </Collapse>
          </Stack>

          <Box display="flex" alignItems="center">
            {(canViewCompanySettings || canReadNotifications) && (
              <Stack direction="row" spacing={2} mr={3.5}>
                <Collapse in={!creationForms} orientation="horizontal">
                  <Search
                    isOpen={isSearchOpen.isTrue}
                    onClick={isSearchOpen.toggle}
                  />
                </Collapse>

                <Help />
                <MailLink />
                {canReadNotifications && <Notifications />}
              </Stack>
            )}
            <MyProfile />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  )
}
