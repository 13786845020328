export const paths = {
  // SIGN UP
  signup: '/signup',

  // SIGN IN
  login: '/login',
  loginGoogle: '/login/google',
  inviteGoogle: '/invite/google',
  forgotPassword: '/login/forgot-password',
  companyName: '/login/company-name',

  // SUPPORT
  support: '/support',

  // FROZEN
  frozenAccount: '/frozen-account',

  // CONFIRMATIONS
  createCompany: '/confirmations/email',
  finishInvitation: '/confirmations/invitation',
  passwordRecovery: '/confirmations/password/recovery',
  passwordChange: '/confirmations/password/change',

  // CHOOSE SUBSCRIPTION
  chooseSubscription: '/choose-subscription',
  startFirstSubscription: '/start-first-subscription',
  startSubscription: '/start-subscription/:subscriptionId',
  startPromotionSubscription: '/start-promotion-subscription/:subscriptionId',

  // NOTIFICATIONS
  notifications: '/notifications',

  // CONTACTS
  contacts: '/contacts',
  createContact: '/contacts/new',
  createContactWithFile: '/contacts/new/with-file',
  editContact: '/contacts/:contactId/edit',
  contactProfile: '/contacts/:contactId',
  contactDetails: '/contacts/:contactId/details',
  contactHistory: '/contacts/:contactId/history',
  contactCommunication: '/contacts/:contactId/communication',
  importContacts: '/contacts/import',
  importContactsSteps: '/contacts/import/steps',

  // TALENTS POOLS
  talentsPools: '/talents-pools',
  talentsPoolContacts: '/talents-pools/:talentPoolId',

  // FILTERS
  historyFilters: '/history-filters',
  savedFilters: '/saved-filters',

  // JOBS
  jobs: '/jobs',
  createJob: '/jobs/new',
  editJob: '/jobs/:id/edit',
  jobProfile: '/jobs/:id',
  jobFlow: '/jobs/:id/flow',
  jobDetails: '/jobs/:id/details',
  jobHistory: '/jobs/:id/history',

  // JOB REQUESTS
  jobRequests: '/job-requests',
  createJobRequest: '/job-requests/new',
  jobRequest: '/job-requests/:id',
  editJobRequest: '/job-requests/:id/edit',

  // ACTIVITIES
  activities: '/activities',

  // REPORTS
  reports: '/reports',
  recruiterPerformanceReport: '/reports/recruiter-performance',
  jobsOverviewReport: '/reports/jobs-overview',
  contactsOverviewReport: '/reports/contacts-overview',
  myRecruiterPerformanceReport: '/my-recruiter-performance',

  // DASHBOARDS
  teamsDashboard: '/teams-dashboard',
  recruiterDashboard: '/recruiter-dashboard',

  // MAILBOX
  mailbox: '/mailbox',
  mailboxFolder: '/mailbox/:folder',
  mailboxThread: '/mailbox/:folder/:threadId',

  // SETTINGS
  settings: '/settings',

  // SETTINGS COMPANY
  settingsGeneral: '/settings/general',
  settingsEditGeneral: '/settings/general/edit',
  settingsConfiguration: '/settings/configuration',
  settingsEditPositions: '/settings/configuration/positions',
  settingsEditProjects: '/settings/configuration/projects',
  settingsEditDepartments: '/settings/configuration/departments',
  settingsCompanyIntegrations: '/settings/company-integrations',
  settingsCareerSite: '/settings/career-site',

  // SETTINGS ACCOUNT
  personalData: '/settings/personal-data',
  profileIntegrations: '/settings/account-integrations',
  profileInterviewScheduling: '/settings/interview-scheduling',
  settingsSubscriptions: '/settings/subscriptions',
  subscriptionDowngrade: '/subscriptions/downgrade/:subscriptionId',
  subscriptionUpgrade: '/subscriptions/upgrade/:subscriptionId',
  settingsBilling: '/settings/billing',
  settingsAddPaymentMethod: '/settings/billing/add-payment-method',

  // SETTINGS USERS
  settingsUsersAndPermissions: '/settings/users-and-permissions',
  settingsUserAndPermissionsProfile: '/settings/users-and-permissions/:id',

  // SETTINGS SKILLS
  settingsSetOfSkills: '/settings/set-of-skills',

  // SETTINGS PIPELINE TEMPLATES
  settingsPipelineTemplates: '/settings/pipeline-templates',

  // SETTINGS JOB
  settingsJob: '/settings/job',
  settingsEditJobTypes: '/settings/job/job-types',
  settingsEditLanguages: '/settings/job/languages',
  settingsEditWorkExperiences: '/settings/job/work-experiences',
  settingsEditRejectReasons: '/settings/job/reject-reasons',

  // SETTINGS KPI
  settingsContactsAddition: '/settings/contacts-addition',
  settingsEditContactsAddition: '/settings/contacts-addition/edit',
  settingsWorkload: '/settings/workload',
  settingsEditWorkload: '/settings/workload/edit',

  // SETTINGS DONOR COMPANIES
  settingsDonorCompanies: '/settings/donor-companies',

  // SETTINGS SOURCES
  settingsSources: '/settings/sources',
  settingsEditSources: '/settings/sources/edit',

  // SETTINGS APPROVERS
  settingsApprovers: '/settings/approvers',

  settingsEmailTemplates: '/settings/email-templates',

  // SETTINGS CUSTOM FIELDS
  settingsCustomFields: '/settings/custom-fields',

  // BOOK MEETING
  bookMeeting: '/book-meeting',

  // PUBLISHED JOB
  publishedJob: '/:companySlug/jobs/:positionSlug/:jobId',

  // PUBLISHED JOBS
  publishedJobs: '/:companySlug/jobs',

  //DEVELOPMENT
  iconsList: '/icons-list',
} as const
