import * as t from 'io-ts'

const CornerStyleCodec = t.union([
  t.literal('ROUNDED'),
  t.literal('SHARP'),
  t.literal('CIRCULAR'),
])

export type CornerStyle = t.TypeOf<typeof CornerStyleCodec>

const FontStyleCodec = t.union([
  t.literal('HELVETICA'),
  t.literal('ARIAL'),
  t.literal('ARIAL_BLACK'),
  t.literal('VERDANA'),
  t.literal('TAHOMA'),
  t.literal('TREBUCHET_MS'),
  t.literal('IMPACT'),
  t.literal('GILL_SANS'),
  t.literal('TIMES_NEW_ROMAN'),
  t.literal('GEORGIA'),
  t.literal('PALATINO'),
  t.literal('BASKERVILLE'),
  t.literal('ANDALE_MONO'),
  t.literal('COURIER'),
  t.literal('LUCIDA'),
  t.literal('MONACO'),
  t.literal('BRADLEY_HAND'),
  t.literal('BRUSH_SCRIPT_MT'),
  t.literal('COMIC_SANS_MS'),
  t.literal('LUMINARI'),
])

export type FontStyle = t.TypeOf<typeof FontStyleCodec>

const VacanciesDisplayingCodec = t.union([
  t.literal('CARDS'),
  t.literal('LIST'),
])

export type VacanciesDisplaying = t.TypeOf<typeof VacanciesDisplayingCodec>

const ComponentColorCodec = t.union([
  t.literal('CODE_001681'),
  t.literal('CODE_000381'),
  t.literal('CODE_0004C5'),
  t.literal('CODE_590081'),
  t.literal('CODE_C500C0'),
  t.literal('CODE_81007E'),
  t.literal('CODE_FFC24E'),
  t.literal('CODE_FF0A40'),
  t.literal('CODE_00812C'),
  t.literal('CODE_00C5C5'),
  t.literal('CODE_FF0ABF'),
  t.literal('CODE_000000'),
  t.literal('CODE_C59C00'),
])

export type ComponentColor = t.TypeOf<typeof ComponentColorCodec>

const LinkCodec = t.strict({
  link: t.string,
  name: t.string,
})

export type CareerSiteLink = t.TypeOf<typeof LinkCodec>

export const CareerSiteConfigCodec = t.intersection([
  t.strict({
    cornerStyle: CornerStyleCodec,
    fontStyle: FontStyleCodec,
    vacanciesDisplaying: VacanciesDisplayingCodec,
    componentColor: ComponentColorCodec,
    activeCoverImage: t.boolean,
    activeTitle: t.boolean,
    activeLinks: t.boolean,
    links: t.array(LinkCodec),
  }),
  t.partial({
    title: t.string,
    subtitle: t.string,
    companyLogo: t.strict({
      fileId: t.string,
      name: t.string,
      fileUrl: t.string,
    }),
    coverImage: t.strict({
      fileId: t.string,
      name: t.string,
      fileUrl: t.string,
    }),
  }),
])

export type CareerSiteConfig = t.TypeOf<typeof CareerSiteConfigCodec>
