import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Typography } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CompanyAccountType, createCompany, getDataByIP, qk } from 'api'
import { useAuthContext } from 'app/auth'
import { env } from 'app/env'
import { UnauthLayout } from 'components/templates'
import { useDictionaries, useIndexPage } from 'lib/app-helpers'
import { useRedirectAuth } from 'lib/react-utils'
import { companyAccountTypes } from 'lib/records'
import { showToast } from 'lib/toast'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Button } from 'ui/inputs/button'
import { renderSelect } from 'ui/inputs/select'
import { renderTextField } from 'ui/inputs/text-field'
import { z } from 'zod'

const buildSchema = (errorMessage: string) => {
  return z.object({
    companyName: z.string().min(1, errorMessage).max(500, errorMessage),
    companyAccountType: z.string().min(1),
  })
}

type Props = Readonly<{
  createCompanyToken: string
}>

const LANGUAGE_DEFAULT = 'ENG'
const WEEK_START_DAY = 'MONDAY'

export const CreateNewCompanyPage = ({ createCompanyToken }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { dictionaries } = useDictionaries()
  const { authorize } = useAuthContext()
  const schema = buildSchema(
    t('validations.enter_value_between_symbols', {
      min: 1,
      max: 500,
    }),
  )

  const $dataByIP = useQuery(qk.external.dataByIP.toKey(), getDataByIP)
  const queryClient = useQueryClient()

  const { redirectToRememberedAuthUrl } = useRedirectAuth()
  const { getIndexPageByUserRole } = useIndexPage()

  const { handleSubmit, control, watch } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      companyName: '',
      companyAccountType: 'COMPANY',
    },
    resolver: zodResolver(schema),
  })

  const $createCompany = useMutation(createCompany, {
    onSuccess: ({ accessToken }) => {
      authorize({ accessToken }, 'signup', user => {
        redirectToRememberedAuthUrl(
          getIndexPageByUserRole(user.role, user.userId),
        )
        queryClient.refetchQueries(qk.subscriptions.currentSubscription.toKey())
      })
    },
    onError: () => {
      navigate('/login', { state: { saveToasts: true } })
      showToast({
        type: 'error',
        title: t('errors.something_wrong'),
      })
    },
  })

  const browserLanguage = navigator.language.split('-')[0]?.toUpperCase()
  const watchedCompanyAccountType = watch(
    'companyAccountType',
  ) as CompanyAccountType

  return (
    <UnauthLayout>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(({ companyName, companyAccountType }) => {
          // Unfortunately, Java on our server has "Europe/Kiev"
          // Quick and dirty fix to handle this case
          const timeZone =
            $dataByIP?.data?.timezone === 'Europe/Kyiv'
              ? 'Europe/Kiev'
              : $dataByIP?.data?.timezone ?? 'Europe/Kiev'

          const languageCode =
            dictionaries.companyLanguages.options.find(
              lang => lang.value === browserLanguage,
            )?.value || LANGUAGE_DEFAULT

          $createCompany.mutate({
            accessToken: createCompanyToken,
            client: env.REACT_APP_CLIENT_ID,
            companyName,
            countryCode: $dataByIP.data?.country ?? '',
            languageCode: languageCode,
            startWeekDay: WEEK_START_DAY,
            zoneId: timeZone,
            companyAccountType: companyAccountType as CompanyAccountType,
          })
        })}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Controller
          control={control}
          name="companyName"
          render={renderTextField({
            label: t('common.company_name'),
            inputProps: { 'data-cy': 'loginCompanyNameInput' },
            required: true,
          })}
        />

        <Box mt={2}>
          <Controller
            name="companyAccountType"
            control={control}
            render={renderSelect({
              required: true,
              disableEmptyOption: true,
              options: companyAccountTypes.map(companyAccountType => ({
                value: companyAccountType,
                label: t(`common.company_account_type.${companyAccountType}`),
              })),
              helperText: t(
                `common.company_account_type_hint.${watchedCompanyAccountType}`,
              ),
            })}
          />
        </Box>

        <Box bgcolor="red.100" padding="8px" width="100%" mt={2}>
          <Typography variant="body2" textAlign="center" color="error.main">
            Your company does not exist. Please create a new one.
          </Typography>
        </Box>

        <Button
          type="submit"
          fullWidth
          loading={$createCompany.isLoading}
          disabled={$dataByIP.isLoading}
          sx={{ marginTop: '24px' }}
        >
          {t('common.sign_up')}
        </Button>
      </Box>
    </UnauthLayout>
  )
}
