import 'normalize.css'
import 'styles/globals.css'
import 'styles/fonts.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/freakflags.css'
import 'react-quill/dist/quill.snow.css'
import 'styles/react-quill-customization.css'

import { ThemeProvider, Typography } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { AuthProvider } from 'app/auth'
import { env } from 'app/env'
import { initI18n } from 'app/i18n'
import { theme } from 'app/mui-theme'
import { useGlobalQueryClient } from 'app/query-client'
import { GlobalLoadingIndicator, RuntimeErrorDialog } from 'components/global'
import { useSetZodErrorMap } from 'lib/form-utils/use-set-zod-error-map'
import mixpanel from 'mixpanel-browser'
import { StrictMode, Suspense, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import { CircularProgress, ToastContainer } from 'ui/feedback'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { Root } from './root'

mixpanel.init(env.REACT_APP_MIXPANEL_TOKEN, {
  loaded: mixpanel => {
    const urlParams = new URLSearchParams(window.location.search)
    const externalDistinctId = urlParams.get('distinct_id')
    if (externalDistinctId) {
      mixpanel.alias(externalDistinctId, mixpanel.get_distinct_id())
    }
  },
})

Sentry.init({
  dsn: 'https://36ae776beb744157b692a517c7391520@o4504485275631616.ingest.sentry.io/4504520518270976',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],
  tracesSampleRate: 0.2,
  environment: env.REACT_APP_ENV,
  release: `${env.REACT_APP_NAME}@${env.REACT_APP_VERSION}`,
  enabled: env.REACT_APP_ENV !== 'development',
})

const errorFallback: Sentry.FallbackRender = errorData => (
  <RuntimeErrorDialog {...errorData} />
)

const setupMSW = () => {
  if (env.REACT_APP_MSW === 'off' || process.env.NODE_ENV !== 'development')
    return

  const { worker } = require('mocks/browser')
  worker.start({
    quiet: env.REACT_APP_MSW === 'quiet',
  })
}

const setupI18n = async (): Promise<void> => {
  await initI18n({
    debug: env.REACT_APP_DEBUG_I18N === 'on',
  })
}

const AppEntry = () => {
  const globalQueryClient = useGlobalQueryClient()

  useSetZodErrorMap()

  return (
    <StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Helmet defaultTitle="Axterior" titleTemplate="%s · Axterior">
          <meta name="description" content="Axterior" />
        </Helmet>
        <QueryClientProvider client={globalQueryClient}>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          <GlobalLoadingIndicator />

          <BrowserRouter>
            <AuthProvider>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  removeDefaultsFromUrl: true,
                }}
              >
                <Sentry.ErrorBoundary
                  {...(env.REACT_APP_ENV === 'development'
                    ? { fallback: errorFallback }
                    : { showDialog: true })}
                >
                  <Suspense fallback={<CircularProgress centered />}>
                    <Root />
                  </Suspense>
                </Sentry.ErrorBoundary>
              </QueryParamProvider>
              <ToastContainer />
            </AuthProvider>
          </BrowserRouter>
        </QueryClientProvider>

        {env.REACT_APP_ENV !== 'production' && (
          <Typography
            variant="caption"
            id="web-version"
            sx={{
              position: 'fixed',
              zIndex: 1_000_000,
              bottom: '8px',
              left: '8px',
              color: 'greyBlue.light',
            }}
          >
            v{env.REACT_APP_VERSION}
          </Typography>
        )}
      </ThemeProvider>
    </StrictMode>
  )
}

const App = Sentry.withProfiler(AppEntry)

const main = async () => {
  setupMSW()
  await setupI18n()

  const container = document.querySelector('#root')
  const root = createRoot(container!)

  root.render(<App />)
}

void main()
