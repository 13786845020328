import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconZapCircle = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 20 20"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M10 4.58366L6.13466 10.206C5.90197 10.5444 5.78562 10.7137 5.79261 10.8543C5.7987 10.9768 5.85846 11.0905 5.95596 11.1649C6.06792 11.2503 6.27329 11.2503 6.68402 11.2503H10V15.417L13.8653 9.79468C14.098 9.45622 14.2144 9.28699 14.2074 9.14632C14.2013 9.02381 14.1415 8.9102 14.044 8.83578C13.9321 8.75033 13.7267 8.75033 13.316 8.75033H10V4.58366Z"
      stroke="#6E47EA"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 18.3337C14.6024 18.3337 18.3333 14.6027 18.3333 10.0003C18.3333 5.39795 14.6024 1.66699 10 1.66699C5.39763 1.66699 1.66667 5.39795 1.66667 10.0003C1.66667 14.6027 5.39763 18.3337 10 18.3337Z"
      stroke="#6E47EA"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
