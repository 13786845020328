import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

export const IconVideoRecorder = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    viewBox="0 0 60 60"
    sx={{
      ...props.sx,
      fill: 'none',
    }}
  >
    <path
      d="M55 22.3284C55 20.8139 55 20.0566 54.7005 19.706C54.4406 19.4017 54.0508 19.2402 53.6519 19.2716C53.1922 19.3078 52.6567 19.8433 51.5858 20.9142L42.5 30L51.5858 39.0858C52.6567 40.1567 53.1922 40.6922 53.6519 40.7284C54.0508 40.7598 54.4406 40.5983 54.7005 40.294C55 39.9434 55 39.1861 55 37.6716V22.3284Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 24.5C5 20.2996 5 18.1994 5.81745 16.5951C6.5365 15.1839 7.68386 14.0365 9.09507 13.3175C10.6994 12.5 12.7996 12.5 17 12.5H30.5C34.7004 12.5 36.8006 12.5 38.4049 13.3175C39.8161 14.0365 40.9635 15.1839 41.6825 16.5951C42.5 18.1994 42.5 20.2996 42.5 24.5V35.5C42.5 39.7004 42.5 41.8006 41.6825 43.4049C40.9635 44.8161 39.8161 45.9635 38.4049 46.6825C36.8006 47.5 34.7004 47.5 30.5 47.5H17C12.7996 47.5 10.6994 47.5 9.09507 46.6825C7.68386 45.9635 6.5365 44.8161 5.81745 43.4049C5 41.8006 5 39.7004 5 35.5V24.5Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)
