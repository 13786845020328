import { Document, Page, StyleSheet, Text, View } from '@react-pdf/renderer'
import { useTranslation } from 'react-i18next'

type Props = Readonly<{
  details: Array<{
    label: string
    value: string
  }>
}>

const styles = StyleSheet.create({
  page: {
    padding: 30,
    fontSize: 12,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 24,
    marginBottom: 24,
    fontWeight: 'bold',
  },
  row: {
    marginBottom: 16,
  },
  label: {
    fontSize: 16,
  },
  value: {
    fontSize: 14,
    marginLeft: 8,
  },
})

export const ContactApplicationPDF = ({ details }: Props) => {
  const { t } = useTranslation()

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}>
            {t('jobs_pipeline.contact_application_dialog.title')}
          </Text>
          {details.map(({ label, value }) => (
            <View key={label + value} style={styles.row}>
              <Text style={styles.label}>
                {label}: <Text style={styles.value}>{value}</Text>
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  )
}
