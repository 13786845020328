import { Box, Stack, Tooltip } from '@mui/material'
import { paths } from 'app/paths'
import { useScopes } from 'app/scopes'
import { IconArrowLeftBar } from 'assets/icons'
import { FiltersTemplate } from 'components/templates'
import { useJobsFilters } from 'lib/app-helpers/jobs'
import { useBoolean } from 'lib/react-utils'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'
import { LinkButton } from 'ui/navigation'

import { JobsFilters } from './jobs-filters'
import { JobsTable } from './jobs-table'

export const JobsTableWithFilters = () => {
  const { t } = useTranslation()

  const {
    filters,
    isSelectedFiltersEmpty,
    applyFilters,
    resetFilters,
    pagination,
    order,
    isAppliedFiltersEmpty,
  } = useJobsFilters()

  const { canManageJob } = useScopes()

  const showFilters = useBoolean(false)

  return (
    <FiltersTemplate
      isOpen={showFilters.isTrue}
      filtersContent={
        <JobsFilters
          filters={{
            statuses: {
              value: filters.statuses.state,
              setValue: filters.statuses.setState,
            },
            departments: {
              value: filters.departments.state,
              setValue: filters.departments.setState,
            },
            projects: {
              value: filters.projects.state,
              setValue: filters.projects.setState,
            },
            recruiters: {
              value: filters.recruiters.state,
              setValue: filters.recruiters.setState,
            },
            skills: {
              value: filters.skills.state,
              setValue: filters.skills.setState,
            },
            dateFrom: {
              value: filters.dateFrom.state,
              setValue: filters.dateFrom.setState,
            },
            dateTo: {
              value: filters.dateTo.state,
              setValue: filters.dateTo.setState,
            },
          }}
          onFiltersApply={applyFilters}
          onFiltersReset={resetFilters}
          onClose={showFilters.setFalse}
          isSelectedFiltersEmpty={isSelectedFiltersEmpty}
        />
      }
    >
      <Box
        sx={{
          pl: 4,
          background: '#FFFFFF',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <JobsTable
          statusesFilter={filters.statuses.appliedState}
          departmentsFilter={filters.departments.appliedState}
          projectsFilter={filters.projects.appliedState}
          recruitersFilter={filters.recruiters.appliedState}
          skillFilter={filters.skills.appliedState}
          dateFromFilter={filters.dateFrom.appliedState}
          dateToFilter={filters.dateTo.appliedState}
          pagination={pagination}
          initialOrder={order}
          isFiltersApplied={!isAppliedFiltersEmpty}
          onGetJobs={jobs => {
            if (jobs.length === 0) {
              showFilters.setTrue()
            }
          }}
          actionButtons={
            <Stack direction="row" alignItems="center" spacing={2}>
              {canManageJob && (
                <LinkButton to={paths.createJob} size="large">
                  {t('common.new_job')}
                </LinkButton>
              )}

              {showFilters.isFalse && (
                <IconButton onClick={showFilters.setTrue}>
                  <Tooltip title="Show filters">
                    <Box>
                      <IconArrowLeftBar />
                    </Box>
                  </Tooltip>
                </IconButton>
              )}
            </Stack>
          }
        />
      </Box>
    </FiltersTemplate>
  )
}
