import {
  Collapse,
  menuClasses,
  Select as MuiSelect,
  SvgIconProps,
  Typography,
} from '@mui/material'
import { SxProps } from '@mui/system'
import { IconChevronDown } from 'assets/icons'
import { checkScrollToBottom } from 'lib/js-utils'
import { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure } from 'react-use'
import {
  FormControl,
  FormControlWrapper,
  InputBase,
  LoadingMoreOption,
  MenuOption,
} from 'ui/inputs/common'
import { TruncateWithTooltip } from 'ui/utils'

type Option<Value extends string> = {
  value: Value
  label: string
  content?: React.ReactNode
}

export type SelectProps<Value extends string> = FormControlWrapper & {
  value: Value
  onChange: (value: Value, option: Option<Value> | null) => void
  options: Array<Option<Value>>

  placeholder?: string
  loading?: boolean
  disabled?: boolean
  small?: boolean
  fontSize?: string
  emptyOptionText?: string
  disableEmptyOption?: boolean
  disableAlphabeticalOrder?: boolean
  hasMoreOptions?: boolean
  onLoadMore?: () => void
  isLoadingMore?: boolean
  loadingError?: boolean
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  dataCy?: string
  sx?: SxProps
  Icon?: (props: SvgIconProps) => JSX.Element
}

const SelectBase = <Value extends string>(
  props: SelectProps<Value>,
  ref: React.Ref<HTMLInputElement>,
) => {
  const { t } = useTranslation()

  const [selectRef, { width }] = useMeasure()

  const selectedOption = props.options.find(
    option => option.value === props.value,
  )

  const optionProps = {
    small: props.small,
    fontSize: props.fontSize,
    disableRipple: true,
  }

  const renderFirstOption = () => {
    if (props.loading) {
      return (
        <MenuOption value="LOADING" disabled {...optionProps}>
          {t('common.loading')}
        </MenuOption>
      )
    }

    if (props.loadingError) {
      return (
        <MenuOption value="LOADING_ERROR" disabled {...optionProps}>
          {t('common.failed_to_load_options')}
        </MenuOption>
      )
    }

    if (!props.disableEmptyOption) {
      return (
        <MenuOption value="" {...optionProps}>
          {props.emptyOptionText ?? t('common.none')}
        </MenuOption>
      )
    }

    if (props.options.length === 0) {
      return (
        <MenuOption value="NO_OPTIONS" disabled {...optionProps}>
          {t('common.no_options')}
        </MenuOption>
      )
    }

    return null
  }

  return (
    <FormControl
      ref={selectRef}
      label={props.label}
      labelAdditionalElement={props.labelAdditionalElement}
      error={props.error}
      helperText={props.helperText}
      onRemove={props.onRemove}
      required={props.required}
      name={props.name}
      hint={props.hint}
    >
      <MuiSelect
        required={props.required}
        input={
          <InputBase
            ref={ref}
            fullWidth
            error={props.error}
            small={props.small}
            sx={{ fontSize: props.fontSize }}
            required={props.required}
            startAdornment={props.startAdornment}
            endAdornment={props.endAdornment}
          />
        }
        IconComponent={props.Icon ?? IconChevronDown}
        displayEmpty={!props.loadingError}
        renderValue={
          props.placeholder && !props.value
            ? () => (
                <Typography
                  sx={{ color: theme => theme.palette.mineShaft[600] }}
                  component="span"
                >
                  {props.placeholder}
                </Typography>
              )
            : () => (
                <TruncateWithTooltip
                  variant="inherit"
                  title={selectedOption?.label}
                  maxWidth="240px"
                >
                  {selectedOption?.label}
                </TruncateWithTooltip>
              )
        }
        value={props.value}
        onChange={event => {
          const newValue = event.target.value as Value
          props.onChange(
            newValue,
            props.options.find(option => option.value === newValue) ?? null,
          )
        }}
        disabled={props.disabled}
        sx={{
          ...props.sx,
        }}
        MenuProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
          transformOrigin: { vertical: 'top', horizontal: 'left' },
          sx: {
            [`& .${menuClasses.paper}`]: {
              // additional 2 pixels are used to include border
              width: width ?? 'auto',
              // input padding
              marginLeft: '-12px',
              marginTop: '12px',

              borderRadius: '6px',
              borderColor: theme => theme.palette.primary.main,
              boxShadow:
                '0px 0px 4px 0px #00000040 inset, 0px 1px 4px 0px #9A7EF0',
            },
          },

          // this is essential for correct custom alignment
          style: { transform: 'translateX(-1px)' },
          MenuListProps: {
            onScroll: event => {
              if (
                props.onLoadMore &&
                props.hasMoreOptions &&
                !props.isLoadingMore
              ) {
                checkScrollToBottom(event, props.onLoadMore)
              }
            },
          },
        }}
        inputProps={{
          'data-cy': props.dataCy,
        }}
      >
        {renderFirstOption()}

        {[...props.options]
          .sort((a, b) =>
            props.disableAlphabeticalOrder ? 0 : a.label.localeCompare(b.label),
          )
          .map((option, index) => (
            <MenuOption
              key={option.value}
              value={option.value}
              {...optionProps}
              data-cy={`${props.dataCy}Item${index}`}
              sx={{
                '&.Mui-selected': {
                  visibility: 'hidden',
                  height: 0,
                  minHeight: 0,
                  padding: 0,
                  margin: 0,
                },
              }}
            >
              {option.content ?? option.label}
            </MenuOption>
          ))}

        <Collapse in={props.isLoadingMore}>
          <MenuOption disabled {...optionProps}>
            <LoadingMoreOption />
          </MenuOption>
        </Collapse>
      </MuiSelect>
    </FormControl>
  )
}

export const Select = forwardRef(SelectBase) as <Value extends string>(
  props: SelectProps<Value> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof SelectBase>
