import { env } from 'app/env'
import { Navigate } from 'react-router-dom'

import { paths } from '../paths'
import { buildRoute, Route } from './build-route'

export const authRoutes: Array<Route> = [
  buildRoute({
    path: paths.notifications,
    factory: () => import('pages/notifications'),
    scopes: ['canReadNotifications'],
  }),
  buildRoute({
    path: paths.contacts,
    factory: () => import('pages/contacts'),
    scopes: ['canViewContacts'],
  }),
  buildRoute({
    path: paths.createContact,
    factory: () => import('pages/contacts/create'),
    scopes: ['canManageContact'],
  }),
  buildRoute({
    path: paths.createContactWithFile,
    factory: () => import('pages/contacts/create-from-cv'),
    scopes: ['canUploadCv'],
  }),
  buildRoute({
    path: paths.editContact,
    factory: () => import('pages/contacts/edit'),
    scopes: ['canManageContact'],
  }),
  buildRoute({
    path: paths.contactProfile,
    factory: () => import('pages/contacts/profile'),
    scopes: ['canViewContacts'],
    children: [
      buildRoute({
        path: paths.contactDetails,
        factory: () => import('pages/contacts/profile/details'),
      }),
      buildRoute({
        path: paths.contactHistory,
        scopes: ['canViewContacts'],
        factory: () => import('pages/contacts/profile/history'),
      }),
      buildRoute({
        path: paths.contactCommunication,
        scopes: ['canViewContacts'],
        factory: () => import('pages/contacts/profile/communication'),
      }),
      {
        index: true,
        path: '*',
        element: <Navigate to="details" replace />,
      },
    ],
  }),
  buildRoute({
    path: paths.importContacts,
    factory: () => import('pages/contacts/import'),
    scopes: ['canImportContacts'],
    children: [
      buildRoute({
        path: paths.importContactsSteps,
        factory: () => import('pages/contacts/import/import-steps'),
      }),
    ],
  }),
  buildRoute({
    path: paths.talentsPools,
    factory: () => import('pages/talent-pools'),
    scopes: ['canViewTalentsPool'],
  }),
  buildRoute({
    path: paths.talentsPoolContacts,
    factory: () => import('pages/talent-pools/talent-pools-contacts-page'),
    scopes: ['canViewTalentsPool'],
  }),
  buildRoute({
    path: paths.historyFilters,
    factory: () => import('pages/contacts/history-filters'),
    scopes: ['canViewContacts'],
  }),
  buildRoute({
    path: paths.savedFilters,
    factory: () => import('pages/contacts/saved-filters'),
    scopes: ['canViewContacts'],
  }),
  buildRoute({
    path: paths.jobs,
    factory: () => import('pages/jobs'),
    scopes: ['canViewJobs'],
  }),
  buildRoute({
    path: paths.createJob,
    factory: () => import('pages/jobs/create'),
    scopes: ['canManageJob'],
  }),
  buildRoute({
    path: paths.editJob,
    factory: () => import('pages/jobs/edit'),
    scopes: ['canManageJob'],
  }),
  buildRoute({
    path: paths.jobProfile,
    factory: () => import('pages/jobs/profile'),
    scopes: ['canViewJobs'],
    children: [
      buildRoute({
        path: paths.jobFlow,
        factory: () => import('pages/jobs/profile/pipeline'),
      }),
      buildRoute({
        path: paths.jobDetails,
        factory: () => import('pages/jobs/profile/details'),
      }),
      buildRoute({
        path: paths.jobHistory,
        factory: () => import('pages/jobs/profile/history'),
      }),
      {
        index: true,
        path: '*',
        element: <Navigate to="flow" replace />,
      },
    ],
  }),
  buildRoute({
    path: paths.jobRequests,
    factory: () => import('pages/job-requests'),
    scopes: ['canViewJobRequests'],
  }),
  buildRoute({
    path: paths.createJobRequest,
    factory: () => import('pages/job-requests/create'),
    scopes: ['canManageJobRequest'],
  }),
  buildRoute({
    path: paths.jobRequest,
    factory: () => import('pages/job-requests/details'),
    scopes: ['canViewJobRequests'],
  }),
  buildRoute({
    path: paths.editJobRequest,
    factory: () => import('pages/job-requests/edit'),
    scopes: ['canManageJobRequest'],
  }),
  buildRoute({
    path: paths.activities,
    factory: () => import('pages/activities'),
    scopes: ['canViewActivities'],
  }),
  buildRoute({
    path: paths.reports,
    factory: () => import('pages/reports'),
    scopes: ['canGetRecruiterPerformanceReport'],
    children: [
      buildRoute({
        path: paths.jobsOverviewReport,
        factory: () => import('pages/reports/jobs-overview'),
        scopes: ['canGetRecruiterPerformanceReport'],
      }),
      buildRoute({
        path: paths.contactsOverviewReport,
        factory: () => import('pages/reports/contacts-overview'),
        scopes: ['canGetRecruiterPerformanceReport'],
      }),
      buildRoute({
        path: paths.recruiterPerformanceReport,
        factory: () => import('pages/reports/recruiter-performance'),
        scopes: ['canGetRecruiterPerformanceReport'],
      }),
      {
        index: true,
        element: <Navigate to="jobs-overview" replace />,
      },
    ],
  }),
  buildRoute({
    path: paths.myRecruiterPerformanceReport,
    factory: () => import('pages/reports/my-recruiter-performance'),
    scopes: ['canViewMyRecruiterPerformance'],
  }),
  buildRoute({
    path: paths.teamsDashboard,
    factory: () => import('pages/teams-dashboard'),
    scopes: ['canViewDashboardTeam'],
  }),
  buildRoute({
    path: paths.recruiterDashboard,
    factory: () => import('pages/recruiter-dashboard'),
    scopes: ['canViewMyRecruiterDashboard'],
  }),
  buildRoute({
    path: paths.mailbox,
    factory: () => import('pages/mailbox/mailbox-layout'),
    children: [
      buildRoute({
        path: paths.mailboxFolder,
        factory: () => import('pages/mailbox/mailbox-folder'),
      }),
      buildRoute({
        path: paths.mailboxThread,
        factory: () => import('pages/mailbox/mailbox-thread'),
      }),
      {
        index: true,
        element: <Navigate to="inbox" replace />,
      },
    ],
  }),
  buildRoute({
    path: paths.subscriptionDowngrade,
    factory: () => import('pages/change-subscription/downgrade-subscription'),
    scopes: ['canViewSubscriptions'],
  }),
  buildRoute({
    path: paths.subscriptionUpgrade,
    factory: () => import('pages/change-subscription/upgrade-subscription'),
    scopes: ['canViewSubscriptions'],
  }),
  buildRoute({
    path: paths.settings,
    factory: () => import('pages/settings'),
    children: [
      ...[paths.settingsGeneral, paths.settingsEditGeneral].map(path =>
        buildRoute({
          path,
          factory: () => import('pages/settings/general'),
          scopes: ['canManageCompany'],
        }),
      ),
      ...[
        paths.settingsConfiguration,
        paths.settingsEditPositions,
        paths.settingsEditProjects,
        paths.settingsEditDepartments,
      ].map(path =>
        buildRoute({
          path,
          scopes: ['canViewCompanyConfiguration'],
          factory: () => import('pages/settings/configuration'),
        }),
      ),
      buildRoute({
        path: paths.settingsCompanyIntegrations,
        factory: () => import('pages/settings/company-integrations'),
        scopes: ['canViewCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsCareerSite,
        factory: () => import('pages/settings/career-site'),
        scopes: ['canEditCareerSiteSettings'],
      }),
      buildRoute({
        path: paths.settingsSubscriptions,
        factory: () => import('pages/settings/subscriptions'),
        scopes: ['canViewSubscriptions'],
      }),
      buildRoute({
        path: paths.personalData,
        factory: () => import('pages/settings/personal-data'),
      }),
      buildRoute({
        path: paths.profileIntegrations,
        factory: () => import('pages/settings/account-integrations'),
      }),
      buildRoute({
        path: paths.profileInterviewScheduling,
        factory: () => import('pages/settings/interview-scheduling-links'),
        scopes: ['canManageSchedule'],
      }),
      buildRoute({
        path: paths.settingsBilling,
        factory: () => import('pages/settings/billing'),
        scopes: ['canManagePaymentMethods'],
        children: [
          buildRoute({
            path: paths.settingsAddPaymentMethod,
            factory: () => import('components/billing/add-payment-method'),
          }),
        ],
      }),
      buildRoute({
        path: paths.settingsUsersAndPermissions,
        factory: () => import('pages/settings/users-and-permissions'),
        scopes: ['canViewUserList'],
      }),
      buildRoute({
        path: paths.settingsUserAndPermissionsProfile,
        factory: () => import('pages/settings/users-and-permissions/profile'),
        scopes: ['canViewUserList'],
      }),
      buildRoute({
        path: paths.settingsSetOfSkills,
        factory: () => import('pages/settings/set-of-skills'),
        scopes: ['canViewCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsPipelineTemplates,
        factory: () => import('pages/settings/pipeline-templates'),
        scopes: ['canViewCompanyConfiguration'],
      }),
      ...[
        paths.settingsJob,
        paths.settingsEditJobTypes,
        paths.settingsEditLanguages,
        paths.settingsEditWorkExperiences,
        paths.settingsEditRejectReasons,
      ].map(path =>
        buildRoute({
          path,
          factory: () => import('pages/settings/job-configuration'),
          scopes: ['canViewCompanyConfiguration'],
        }),
      ),
      buildRoute({
        path: paths.settingsContactsAddition,
        factory: () => import('pages/settings/contacts-addition'),
        scopes: ['canViewCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsEditContactsAddition,
        factory: () => import('pages/settings/contacts-addition'),
        scopes: ['canManageCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsWorkload,
        factory: () => import('pages/settings/workload'),
        scopes: ['canViewCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsEditWorkload,
        factory: () => import('pages/settings/workload'),
        scopes: ['canManageCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsDonorCompanies,
        scopes: ['canViewCompanyConfiguration'],
        factory: () => import('pages/settings/donor-companies'),
      }),
      ...[paths.settingsSources, paths.settingsEditSources].map(path =>
        buildRoute({
          path,
          scopes: ['canViewCompanyConfiguration'],
          factory: () => import('pages/settings/sources'),
        }),
      ),
      buildRoute({
        path: paths.settingsApprovers,
        factory: () => import('pages/settings/approvers'),
        scopes: ['canViewCompanyConfiguration'],
      }),
      buildRoute({
        path: paths.settingsEmailTemplates,
        factory: () => import('pages/settings/email-templates'),
        scopes: ['canViewEmailTemplate'],
      }),
      buildRoute({
        path: paths.settingsCustomFields,
        factory: () => import('pages/settings/custom-fields'),
        scopes: ['canViewCompanyConfiguration'],
      }),

      {
        index: true,
        path: '*',
        element: <Navigate to="personal-data" replace />,
      },
    ],
  }),
  ...(env.REACT_APP_ENV === 'development' &&
  window.location.hostname === 'localhost'
    ? [
        buildRoute({
          path: paths.iconsList,
          factory: () => import('pages/development/icons-list'),
          scopes: [],
        }),
      ]
    : []),
]
