import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { addContactsToTalentsPool, getContactsTalentsPoolsList, qk } from 'api'
import { paths } from 'app/routes'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { showToast } from 'lib/toast'
import mixpanel from 'mixpanel-browser'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generatePath, useNavigate } from 'react-router-dom'
import { DialogContent } from 'ui/feedback'
import { renderSelect } from 'ui/inputs/select'
import { z } from 'zod'

const schema = z.object({
  talentPoolId: z.string().min(1),
})

type Props = {
  contactsIds: Array<string>
  onClose: () => void
}

export const AddContactsToTalentsPoolForm = (props: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const $addToTalentsPool = useMutation(addContactsToTalentsPool)

  const { handleSubmit, control } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      talentPoolId: '',
    },
    resolver: zodResolver(schema),
  })

  const $talentsPools = useInfinitePaginatedQuery(
    qk.contacts.talentPools.list,
    getContactsTalentsPoolsList,
    {
      pagination: { page: 0, pageSize: 50 },
      excludeCandidateProfileId:
        props.contactsIds.length === 1 ? props.contactsIds[0] : undefined,
    },
  )

  const count = props.contactsIds.length

  return (
    <DialogContent
      title={t('common.add_contacts_to_talents_pool_count', {
        count,
      })}
      wrapWithForm
      onDeny={props.onClose}
      confirmText={t('common.add')}
      isActionLoading={$addToTalentsPool.isLoading}
      onConfirm={handleSubmit(values => {
        $addToTalentsPool.mutate(
          {
            talentPoolId: values.talentPoolId,
            contactsIds: props.contactsIds,
          },
          {
            onSuccess: data => {
              mixpanel.track('Contacts Added to Talent Pool.', {
                contactsCount: props.contactsIds.length,
              })

              navigate(
                generatePath(paths.talentsPoolContacts, {
                  talentPoolId: data.talentPoolId,
                }),
                { state: { saveToasts: true } },
              )
              showToast({
                title: t('common.added_to_talents_pool', {
                  count,
                }),
              })
              props.onClose()
            },
          },
        )
      })}
    >
      <Controller
        control={control}
        name="talentPoolId"
        render={renderSelect({
          disableEmptyOption: true,
          label: t('common.talents_pool'),
          options:
            $talentsPools.data?.pages.flatMap(page =>
              page.rows
                .filter(pool => pool.modifiable === true)
                .map(pool => ({
                  value: pool.talentPoolId,
                  label: pool.name,
                })),
            ) ?? [],
          disableAlphabeticalOrder: true,
          hasMoreOptions: $talentsPools.hasNextPage,
          onLoadMore: $talentsPools.fetchNextPage,
          isLoadingMore: $talentsPools.isFetchingNextPage,
        })}
      />
    </DialogContent>
  )
}
