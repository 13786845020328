import { Box, Link, Popover, Stack, Tooltip, Typography } from '@mui/material'
import {
  IconHelpCircle,
  IconLayoutAlt03,
  IconLifeBuoy01,
  IconMail01,
  IconMessageDotsCircle,
} from 'assets/icons'
import {
  AXTERIOR_BLOG_LINK,
  AXTERIOR_HELP_CENTER_LINK,
  AXTERIOR_HELP_EMAIL,
} from 'lib/records'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'

// Wait for the HubSpot script to load
document.querySelector('#hs-script-loader')?.addEventListener('load', () => {
  // Initialize the HubSpot chat widget
  window.HubSpotConversations?.widget.load()
})

const openHubSpotChat = (onSubmit: () => void) => {
  // Check if the HubSpot chat widget is available
  if (window.HubSpotConversations?.widget) {
    onSubmit()
    // Open the chat
    window.HubSpotConversations.widget.open()
  }
}
export const Help = () => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const isOpen = Boolean(anchorEl)

  return (
    <Box>
      <Tooltip title={t('support.quick_help')} placement="bottom">
        <IconButton onClick={handleClick}>
          <IconLifeBuoy01
            sx={{
              fontSize: '20px',
              color: theme =>
                isOpen
                  ? theme.palette.primary.main
                  : theme.palette.mineShaft[800],
            }}
          />
        </IconButton>
      </Tooltip>
      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{
          mt: 1,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box width={280} p={2}>
          <Typography variant="h3" mb="16px">
            {t('support.quick_help')}
          </Typography>

          <Stack spacing="8px">
            <Typography fontSize={12} color="text.secondary" lineHeight="16px">
              {t('support.quick_help_description')}:
            </Typography>

            <Stack direction="row" gap={1} alignItems="center">
              <IconMail01
                sx={{
                  fontSize: 16,
                  color: 'greyBlue.light',
                }}
              />{' '}
              <Link
                href={`mailto:${AXTERIOR_HELP_EMAIL}`}
                sx={{
                  fontSize: 14,
                  textDecoration: 'none',
                  color: 'text.primary',
                  ':hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                }}
              >
                {AXTERIOR_HELP_EMAIL}
              </Link>
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              <IconMessageDotsCircle
                sx={{
                  fontSize: 16,
                  color: 'greyBlue.light',
                }}
              />{' '}
              <Typography
                onClick={() => openHubSpotChat(handleClose)}
                fontSize={14}
                sx={{
                  cursor: 'pointer',
                  ':hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                }}
              >
                {t('common.chat')}
              </Typography>
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              <IconHelpCircle
                sx={{
                  fontSize: 16,
                  color: 'greyBlue.light',
                }}
              />{' '}
              <Link
                href={AXTERIOR_HELP_CENTER_LINK}
                sx={{
                  fontSize: 14,
                  textDecoration: 'none',
                  color: 'text.primary',
                  ':hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                }}
                target="_blank"
              >
                {t('support.help_center')}
              </Link>
            </Stack>

            <Stack direction="row" gap={1} alignItems="center">
              <IconLayoutAlt03
                sx={{
                  fontSize: 16,
                  color: 'greyBlue.light',
                }}
              />{' '}
              <Link
                href={AXTERIOR_BLOG_LINK}
                sx={{
                  fontSize: 14,
                  textDecoration: 'none',
                  color: 'text.primary',
                  ':hover': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                }}
                target="_blank"
              >
                {t('common.blog')}
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Popover>
    </Box>
  )
}
