import { Box, Link } from '@mui/material'
import { TruncateAndCopy } from 'ui/utils'

export const ContactLink = ({
  linkHref,
  faviconBase64Data,
  withCopyButton,
}: {
  linkHref: string
  faviconBase64Data: string
  withCopyButton?: boolean
}) => {
  return (
    <Box display="flex" alignItems="center">
      <img
        src={`data:image/png;base64,${faviconBase64Data}`}
        width={16}
        alt="Contact link"
      />

      <Link
        component={Link}
        href={linkHref}
        target="_blank"
        noWrap
        underline="none"
        sx={{
          ml: 1.25,
          mr: 0.5,
        }}
      >
        <TruncateAndCopy
          variant="body2"
          text={linkHref}
          color="mineShaft.main"
          isCopyButtonAlwaysVisible={withCopyButton}
        />
      </Link>
    </Box>
  )
}
