import { ClickAwayListener, Drawer } from '@mui/material'
type Props = {
  isOpened: boolean
  onClose: () => void
  children: React.ReactNode
  /** disable closing preview on click away */
  clickAwayDisabled?: boolean
}

export const previewCloseExceptionClass = 'preview-close-exception'

export const PreviewDrawer = ({
  isOpened,
  onClose,
  children,
  clickAwayDisabled,
}: Props) => {
  return (
    <ClickAwayListener
      onClickAway={event => {
        if (!isOpened || clickAwayDisabled) return

        const eventPath = event.composedPath()

        if (
          !eventPath.some(element => {
            if (element instanceof Element) {
              return element.classList.contains(previewCloseExceptionClass)
            }

            return false
          })
        ) {
          onClose()
        }
      }}
    >
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: '480px',
            marginTop: '16px',
            marginBottom: '16px',
            marginRight: '16px',
            height: 'calc(100% - 32px)',
            boxShadow: `
              0px 0px 1px 0px #0000003D,
              0px 0px 2px 0px #00000029,
              0px 3px 4px 0px #0000000F,
              0px 6px 8px 0px #0000000F,
              0px 12px 16px 0px #00000014,
              0px 18px 32px 0px #0000000F
            `,
          },
        }}
        anchor="right"
        variant="persistent"
        ModalProps={{
          BackdropProps: { invisible: true },
          disableScrollLock: true,
        }}
        onClose={onClose}
        open={isOpened}
      >
        {children}
      </Drawer>
    </ClickAwayListener>
  )
}
