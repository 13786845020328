import { Box, ButtonBase, Stack } from '@mui/material'
import { ComponentColor } from 'api'
import { IconCheck } from 'assets/icons'

export const componentColors: Array<{ code: ComponentColor; color: string }> = [
  { code: 'CODE_001681', color: '#001681' },
  { code: 'CODE_000381', color: '#000381' },
  { code: 'CODE_0004C5', color: '#0004C5' },
  { code: 'CODE_590081', color: '#590081' },
  { code: 'CODE_C500C0', color: '#C500C0' },
  { code: 'CODE_81007E', color: '#81007E' },
  { code: 'CODE_FFC24E', color: '#FFC24E' },
  { code: 'CODE_FF0A40', color: '#FF0A40' },
  { code: 'CODE_00812C', color: '#00812C' },
  { code: 'CODE_00C5C5', color: '#00C5C5' },
  { code: 'CODE_FF0ABF', color: '#FF0ABF' },
  { code: 'CODE_000000', color: '#000000' },
  { code: 'CODE_C59C00', color: '#C59C00' },
]

type Props = Readonly<{
  colorCode: ComponentColor
  onChange: (colorCode: ComponentColor) => void
}>

export const ComponentColorSelect = ({ colorCode, onChange }: Props) => {
  return (
    <Stack direction="row" maxWidth={420} spacing="4px">
      {componentColors.map(({ code, color }) => {
        return (
          <ButtonBase key={code} onClick={() => onChange(code)}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: 24,
                height: 24,
                background: color,
                borderRadius: '2px',
              }}
            >
              {code === colorCode && (
                <IconCheck sx={{ color: 'white', fontSize: '16px' }} />
              )}
            </Box>
          </ButtonBase>
        )
      })}
    </Stack>
  )
}
