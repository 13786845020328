import { usePagination } from '@mui/lab'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { ListPagination } from 'api'
import { IconChevronLeft, IconChevronRight } from 'assets/icons'
import { IconChevronSelectorVertical } from 'assets/icons'
import { useTranslation } from 'react-i18next'
import { Select } from 'ui/inputs/select'

export type Pagination = ListPagination & {
  onChangePage: (page: number) => void
  onChangePageSize: (size: number) => void
}

type Props = Readonly<{
  pagination: Pagination
  totalCount: number
  withRowsByPage?: boolean
}>

export const TablePagination = ({
  pagination: { page, pageSize, onChangePage, onChangePageSize },
  totalCount,
  withRowsByPage = true,
}: Props) => {
  const { t } = useTranslation()
  const totalPages = Math.ceil(totalCount / pageSize)
  const siblingCount = page > 1 && page < totalPages - 1 ? 1 : 0
  const { items } = usePagination({
    count: totalPages,
    siblingCount: siblingCount,
    page: page + 1,
    onChange: (_, page) => onChangePage(page - 1),
  })

  return (
    <Box
      display="flex"
      justifyContent={withRowsByPage ? 'space-between' : 'center'}
      alignItems="center"
    >
      {withRowsByPage && (
        <Stack direction="row" alignItems="center">
          <Typography variant="body2" mr={1} color="textPrimary">
            {t('common.pagination.rows_by_page')}:
          </Typography>

          <Box width="70px">
            <Select
              disableEmptyOption
              Icon={IconChevronSelectorVertical}
              value={pageSize.toString()}
              sx={{
                borderRadius: '35px',
                borderColor: 'mineShaft.200',
                minWidth: 'auto',
                width: 'auto',
              }}
              onChange={value => onChangePageSize(Number(value))}
              options={[10, 25, 50].map(value => ({
                value: value.toString(),
                label: value.toString(),
              }))}
            />
          </Box>
        </Stack>
      )}

      <Stack direction="row" alignItems="center">
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null

          if (type === 'start-ellipsis' || type === 'end-ellipsis') {
            children = (
              <Typography
                sx={{
                  width: 30,
                  height: 30,
                  textAlign: 'center',
                  border: 1,
                  borderRight: 0,
                  borderLeft: 0,
                  borderColor: 'mineShaft.200',
                  fontSize: 12,
                  color: 'text.disabled',
                }}
              >
                …
              </Typography>
            )
          } else if (type === 'page') {
            children = (
              <IconButton
                sx={{
                  borderRadius: 0,
                  border: 1,
                  borderRight: 0,
                  borderLeft: 0,
                  minWidth: 30,
                  height: 30,
                  fontSize: 14,
                  borderColor: 'mineShaft.200',
                  fontWeight: selected ? '500' : undefined,
                  color: selected ? 'mediumPurple.main' : 'mineShaft.main',
                  '&::after': {
                    content: '""',
                    transition: 'width 0.2s',
                    width: selected ? 1 : 0,
                    height: '1px',
                    bgcolor: 'mediumPurple.main',
                    position: 'absolute',
                    top: '100%',
                    left: '50%',
                    transform: 'translate(-50%, -100%)',
                  },
                  '&:hover': {
                    '&::after': {
                      content: '""',
                      transition: 'width 0.2s',
                      width: 1,
                      height: '1px',
                      bgcolor: 'mediumPurple.main',
                      position: 'absolute',
                      top: '100%',
                      left: '50%',
                      transform: 'translate(-50%, -100%)',
                    },
                  },
                }}
                {...item}
              >
                {page}
              </IconButton>
            )
          } else {
            children = (
              <IconButton
                type="button"
                {...item}
                aria-label={type === 'next' ? 'Chevron right' : 'Chevron left'}
                sx={{
                  borderRadius:
                    type === 'next' ? '0 35px 35px 0' : '35px 0 0 35px',
                  border: 1,
                  width: 30,
                  height: 30,
                  borderColor: 'mineShaft.200',
                }}
              >
                {type === 'next' ? (
                  <IconChevronRight
                    sx={{
                      fontSize: 10,
                      color: 'mineShaft.main',
                    }}
                  />
                ) : (
                  <IconChevronLeft
                    sx={{
                      fontSize: 10,
                      color: 'mineShaft.main',
                    }}
                  />
                )}
              </IconButton>
            )
          }

          return <Box key={index}>{children}</Box>
        })}
      </Stack>
    </Box>
  )
}
