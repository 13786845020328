import * as t from 'io-ts'

export const TInvitationUser = t.intersection([
  t.strict({
    userId: t.string,
    fullName: t.string,
    countryCode: t.string,
    languageCode: t.string,
    timeZone: t.string,
    email: t.string,
    companyOwnerName: t.string,
    companyName: t.string,
    role: t.strict({
      name: t.string,
    }),
  }),
  t.partial({ companyLogoUri: t.string }),
])
