import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Stack, useTheme } from '@mui/material'
import { IconX } from 'assets/icons'
import { IconCircleArrowTop } from 'assets/icons/icons-v2/arrows/circle-arrow-top'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconButton } from 'ui/inputs/icon-button'
import { renderTextField } from 'ui/inputs/text-field'
import { TruncateWithTooltip } from 'ui/utils'
import { z } from 'zod'

type Props = {
  message: string
  onSubmit: (values: { message: string }) => void
  isAdding?: boolean
  onCancel?: () => void
}

const schema = z.object({
  message: z.string().trim().min(1).max(300).or(z.literal('')),
})

export const CommentForm = ({
  message,
  onSubmit,
  isAdding,
  onCancel,
}: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const { handleSubmit, control, reset } = useForm<z.infer<typeof schema>>({
    defaultValues: {
      message,
    },
    resolver: zodResolver(schema),
  })

  return (
    <form
      onSubmit={handleSubmit(values => {
        if (isAdding) reset()
        onSubmit(values)
      })}
    >
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        <Box>
          {message && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderLeft: `1px solid ${theme.palette.primary.main}`,
                mb: '10px',
                paddingLeft: '8px',
              }}
            >
              <TruncateWithTooltip
                variant="body2"
                maxWidth="200px"
                color={theme.palette.mineShaft[600]}
              >
                {message}
              </TruncateWithTooltip>
              <Box
                borderRadius="50%"
                sx={{
                  bgcolor: theme => theme.palette.mineShaft[100],
                }}
              >
                <IconButton
                  sizePx="16px"
                  size="small"
                  icon={true}
                  onClick={onCancel}
                >
                  <IconX
                    sx={{
                      fontSize: 10,
                      color: theme => theme.palette.mineShaft.main,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          )}
          <Controller
            control={control}
            name="message"
            render={renderTextField({
              minRows: 2,
              maxRows: 6,
              placeholder: t('common.comment_and_enter'),
              resizable: false,
              multiline: true,
              onKeyPress: event => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault()
                  handleSubmit(values => {
                    if (isAdding) reset()
                    onSubmit(values)
                  })()
                }
              },
              sx: {
                border: '0px',
                borderRadius: '12px',
                background: '#F1EDFD',
              },
            })}
          />
        </Box>

        <IconButton type="submit">
          <IconCircleArrowTop
            sx={{ color: theme.palette.primary.main, fontSize: '32px' }}
          />
        </IconButton>
      </Stack>
    </form>
  )
}
