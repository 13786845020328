import { Box } from '@mui/material'
import { AxteriorLogoDark } from 'components/global'

type Props = Readonly<{
  children: React.ReactNode
  isWithLogo?: boolean
  header?: React.ReactNode
}>

export const UnauthLayout = ({
  children,
  isWithLogo = true,
  header,
}: Props) => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(270deg, #D08EF3 0%, #FFD7AE 100%)',
        padding: '12px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '520px',
          width: '100%',
          background: '#FFFFFF',
          boxSizing: 'border-box',
          borderRadius: '12px',
          border: theme => `1px solid ${theme.palette.divider}`,
          boxShadow: '4px -6px 1px 0px #BE5CEF',
        }}
      >
        {header}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: '40px 60px',
          }}
        >
          {isWithLogo && (
            <Box alignSelf="center" mb="32px">
              <AxteriorLogoDark logoSize={35} />
            </Box>
          )}

          {children}
        </Box>
      </Box>
    </Box>
  )
}
