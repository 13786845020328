import {
  TAuthorizationResponse,
  TAuthorizationResponseByLink,
} from 'api/common'
import { TOAuth2Url } from 'api/company'
import { env } from 'app/env'
import { pipe } from 'fp-ts/function'
import { decodeJson, del, get, post } from 'lib/request'

export const refreshToken = async ({
  countryCode,
}: {
  countryCode?: string
}) => {
  return pipe(
    await post('auth/tokens/refresh', {
      body: {
        clientId: env.REACT_APP_CLIENT_ID,
        countryCode: countryCode || 'UA',
      },
      withoutAuthHeader: true,
    }),
    decodeJson(TAuthorizationResponse),
  )
}

export const login = async ({
  email,
  password,
  countryCode,
}: {
  email: string
  password: string
  countryCode?: string
}) => {
  return pipe(
    await post('auth/tokens/credentials', {
      body: {
        clientId: env.REACT_APP_CLIENT_ID,
        email,
        password,
        countryCode: countryCode || 'UA',
      },
    }),
    decodeJson(TAuthorizationResponse),
  )
}

export const logout = async () => {
  return await del('auth/tokens/me', {})
}

export type SocialProviderLinkReason = 'INVITE' | 'LOGIN'

export type GetSocialProviderLinkInput = {
  providerType: 'GOOGLE' | 'EMAIL'
  reason: SocialProviderLinkReason
}

export const getSocialProviderLink = async ({
  providerType,
  reason,
}: GetSocialProviderLinkInput) => {
  const params = new URLSearchParams({ reason })

  return pipe(
    await get(`auth/social/providers/${providerType}`, { query: params }),
    decodeJson(TOAuth2Url),
  )
}

export type RegisterInput = {
  clientId: string
  type: 'GOOGLE'
  code: string
  reason: 'login' | 'invite'
  promotionSlug?: string | null
  languageCode?: string
  countryCode: string
  zoneId?: string
}

export const registerBySocialProviderLink = async ({
  reason,
  ...rest
}: RegisterInput) => {
  return pipe(
    await post(`auth/social/${reason}`, {
      body: {
        ...rest,
        clientId: env.REACT_APP_CLIENT_ID,
      },
    }),
    decodeJson(TAuthorizationResponseByLink),
  )
}
