import {
  alpha,
  Box,
  ClickAwayListener,
  InputAdornment,
  Popper,
  useTheme,
} from '@mui/material'
import { getGlobalSearchHistory, qk } from 'api'
import { IconSearchLg, IconX } from 'assets/icons'
import { useInfinitePaginatedQuery } from 'lib/react-query-utils'
import { useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { IconButton } from 'ui/inputs/icon-button'
import { TextField } from 'ui/inputs/text-field'

import { GlobalSearchHistory } from './global-search-history'
import { GlobalSearchResult } from './global-search-result'

type Props = Readonly<{
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}>

const SEARCH_WIDTH = 440

export const GlobalSearch = ({ isOpen, onOpen, onClose }: Props) => {
  const theme = useTheme()

  const anchor = useRef<HTMLElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)

  const [searchParams, setSearchParams] = useSearchParams()
  const searchQuery = searchParams.get('search')

  const [value, setValue] = useState(searchQuery ?? '')
  const [searchValue, setSearchValue] = useState(value)

  const $history = useInfinitePaginatedQuery(
    qk.globalSearch.history,
    getGlobalSearchHistory,
    { pagination: { page: 0, pageSize: 25 } },
  )

  const history = $history.data?.pages.flatMap(page => page.rows) ?? []
  const historyNotEmpty = history.length > 0

  const focusInput = () => {
    inputRef.current?.focus()
  }

  return (
    <ClickAwayListener
      onClickAway={() => {
        onClose()
        setValue(searchValue)
      }}
    >
      <Box>
        <Box ref={anchor}>
          <TextField
            inputRef={inputRef}
            inputProps={{ maxLength: 50 }}
            startAdornment={
              <InputAdornment position="start">
                <IconSearchLg sx={{ color: 'mineShaft', fontSize: '15px' }} />
              </InputAdornment>
            }
            endAdornment={
              value.trim() && (
                <InputAdornment position="end">
                  <IconButton
                    sizePx={24}
                    onClick={() => {
                      setValue('')
                      setSearchValue('')
                      setSearchParams(params => {
                        params.delete('search')
                        return params
                      })
                      if (isOpen) {
                        focusInput()
                      }
                      if (!historyNotEmpty) {
                        onClose()
                      }
                    }}
                  >
                    <IconX sx={{ color: 'mineShaft', fontSize: '12px' }} />
                  </IconButton>
                </InputAdornment>
              )
            }
            onKeyPress={event => {
              if (event.key === 'Enter' && value.trim().length > 0) {
                onOpen()
                setSearchValue(value.trim())
              }
            }}
            onFocus={() => {
              if (searchValue || historyNotEmpty) {
                onOpen()
              }
            }}
            value={value}
            onChange={setValue}
            sx={{
              bgcolor: alpha('#FFFFFF', 0.3),
              border: 'none',
              transition: 'width 0.5s',
              height: '28px !important',
              width: { md: SEARCH_WIDTH, xs: '100%' },
            }}
          />
        </Box>
        <Popper
          open={isOpen}
          anchorEl={anchor.current}
          placement="bottom"
          style={{
            maxWidth: SEARCH_WIDTH,
            width: '100%',
            zIndex: theme.zIndex.modal,
            borderRadius: '5px',
          }}
          modifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ]}
        >
          {searchValue ? (
            <GlobalSearchResult
              searchValue={searchValue}
              onClose={onClose}
              refetchHistory={$history.refetch}
            />
          ) : (
            <GlobalSearchHistory
              history={history}
              onSelectQuery={query => {
                setValue(query)
                setSearchValue(query)
                focusInput()
              }}
              onScrollToBottom={() => {
                if ($history.hasNextPage && !$history.isFetchingNextPage) {
                  $history.fetchNextPage()
                }
              }}
              onClear={() => {
                $history.refetch()
                focusInput()
                onClose()
              }}
            />
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}
