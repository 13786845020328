import { Divider, Stack } from '@mui/material'
import { range } from 'fp-ts/lib/ReadonlyNonEmptyArray'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Document, pdfjs } from 'react-pdf'
import { Dialog } from 'ui/feedback'

import { DocumentPreviewPage } from './document-preview-page'
import { PreviewDialogContent } from './preview-dialog-content'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

type Props = {
  name: string
  url: string
  isOpen: boolean
  onClose: () => void
}

export const DocumentPreviewDialog = ({
  name,
  url,
  isOpen,
  onClose,
}: Props) => {
  const { t } = useTranslation()

  const [numPages, setNumPages] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(100)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset)
  }

  const goToPrevPage = () => {
    return changePage(-1)
  }

  const goToNextPage = () => {
    return changePage(1)
  }

  const closeDialog = () => {
    onClose()
    setPageNumber(1)
  }

  return (
    <Dialog size="large" open={isOpen} onClose={closeDialog}>
      <PreviewDialogContent
        title={t('common.preview_document', { name })}
        url={url}
        fileName={name}
        onDeny={closeDialog}
        pageNumber={pageNumber}
        numPages={numPages}
        goToPrevPage={goToPrevPage}
        goToNextPage={goToNextPage}
        scale={scale}
        setScale={setScale}
        isShowNavigationButtons={false}
      >
        <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
          <Stack divider={<Divider />}>
            {range(1, numPages).map(pageNumber => (
              <DocumentPreviewPage
                key={pageNumber}
                scale={scale}
                pageNumber={pageNumber}
                onView={() => setPageNumber(pageNumber)}
              />
            ))}
          </Stack>
        </Document>
      </PreviewDialogContent>
    </Dialog>
  )
}
