import { FontStyle, VacanciesDisplaying } from 'api'

export const fontStyles: Array<FontStyle> = [
  'HELVETICA',
  'ARIAL',
  'ARIAL_BLACK',
  'VERDANA',
  'TAHOMA',
  'TREBUCHET_MS',
  'IMPACT',
  'GILL_SANS',
  'TIMES_NEW_ROMAN',
  'GEORGIA',
  'PALATINO',
  'BASKERVILLE',
  'ANDALE_MONO',
  'COURIER',
  'LUCIDA',
  'MONACO',
  'BRADLEY_HAND',
  'BRUSH_SCRIPT_MT',
  'COMIC_SANS_MS',
  'LUMINARI',
]

export const vacanciesDisplaying: Array<VacanciesDisplaying> = ['CARDS', 'LIST']
