import { Box, Tooltip } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import { getJobsList, JobRow, ListOrder, qk } from 'api'
import { paths } from 'app/routes'
import { useScopes } from 'app/scopes'
import { IconBriefcase01 } from 'assets/icons'
import programmerImage from 'assets/images/programmer.png'
import { previewCloseExceptionClass, PreviewDrawer } from 'components/common'
import { ContactNameWithAvatar } from 'components/contacts'
import { JobPreview, JobStatus, JobTargetDate } from 'components/jobs'
import { ErrorTemplate, NoDataTemplate } from 'components/templates'
import { endOfDay, startOfDay } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import {
  useFormatLocation,
  useGetNameWithStatus,
  useMyTimeZone,
} from 'lib/app-helpers'
import { dash } from 'lib/js-utils'
import { renderQueryResult } from 'lib/react-query-utils'
import { jobPriorities } from 'lib/records'
import { ReactNode, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { generatePath, useSearchParams } from 'react-router-dom'
import {
  Column,
  Pagination,
  Table,
  TableSkeleton,
  TableTotalCount,
} from 'ui/data'
import { Link } from 'ui/navigation/link'
import { AbsolutelyCentered, TruncateWithTooltip } from 'ui/utils'

import { FilterArr } from './jobs-filters'

export type Selected = Array<string>

type Props = {
  selected?: Selected
  setSelected?: (keys: Selected) => void
  statusesFilter?: FilterArr
  departmentsFilter?: FilterArr
  projectsFilter?: FilterArr
  recruitersFilter?: FilterArr
  contactId?: string
  skillFilter?: FilterArr
  dateFromFilter?: Date | null
  dateToFilter?: Date | null
  pagination: Pagination
  noDataTemplate?: ReactNode
  actionButtons?: ReactNode
  initialOrder?: ListOrder
  isFiltersApplied?: boolean
  onGetJobs?: (jobs: Array<JobRow>) => void
}

export const JobsTable = ({
  selected,
  setSelected,
  statusesFilter,
  departmentsFilter,
  projectsFilter,
  recruitersFilter,
  skillFilter,
  dateFromFilter,
  dateToFilter,
  pagination,
  contactId,
  noDataTemplate,
  actionButtons,
  initialOrder,
  isFiltersApplied,
  onGetJobs,
}: Props) => {
  const { t } = useTranslation()
  const timeZone = useMyTimeZone()
  const { canViewJobs, canManageJob } = useScopes()
  const formatLocation = useFormatLocation()
  const { getNameWithStatus } = useGetNameWithStatus()

  const [previewId, setPreviewId] = useState<string | null>(null)

  const [order, setOrder] = useState<ListOrder>(
    initialOrder ?? {
      column: 'priority',
      direction: 'desc',
    },
  )

  const [searchParams] = useSearchParams()
  const searchQuery = searchParams.get('search')

  const args = {
    pagination,
    order,
    candidateExclude: contactId,
    filters: {
      statuses: statusesFilter,
      departments: departmentsFilter,
      projects: projectsFilter,
      skills: skillFilter,
      recruiters: recruitersFilter,
      from: dateFromFilter
        ? zonedTimeToUtc(startOfDay(dateFromFilter), timeZone)
        : null,
      to: dateToFilter
        ? zonedTimeToUtc(endOfDay(dateToFilter), timeZone)
        : null,
    },
    search: searchQuery ?? undefined,
  }

  const $jobs = useQuery(
    qk.jobs.list.toKeyWithArgs(args),
    () => getJobsList(args),
    {
      keepPreviousData: true,
      onSuccess: jobsData => onGetJobs?.(jobsData.rows),
    },
  )

  const columns = useMemo<Array<Column>>(() => {
    return [
      { name: '', key: 'status', width: 16, sx: { pr: 0 } },
      { name: t('common.title'), key: 'title', sortable: true },
      { name: t('common.department'), key: 'department', sortable: true },
      { name: t('common.project'), key: 'project', sortable: true },
      { name: t('common.location'), key: 'address', sortable: true },
      { name: t('common.target_date'), key: 'target_date', sortable: true },
      {
        name: t('common.recruiter'),
        key: 'recruiters',
        sortable: true,
        sx: { maxWidth: 250 },
      },
      {
        name: t('common.priority'),
        key: 'priority',

        sortable: true,
      },
      {
        name: t('common.applied'),
        key: 'applied',

        sortable: true,
      },
      {
        name: t('common.days'),
        key: 'days',
      },
      { name: t('common.published'), key: 'published', sortable: true },
    ]
  }, [t])

  return renderQueryResult($jobs, {
    loading: () => {
      return <TableSkeleton columnsCount={7} />
    },
    error: error => {
      return (
        <AbsolutelyCentered>
          <ErrorTemplate error={error} />
        </AbsolutelyCentered>
      )
    },
    success: jobsData => {
      const rows = jobsData.rows.map(job => {
        const recruiter = job.recruiters[0]
        const priority = jobPriorities[job.priority]
        const location = formatLocation(
          job.address?.country?.code,
          job.address?.city,
        )

        return {
          key: job.jobId,
          onClick: () => setPreviewId(job.jobId),
          cells: [
            <JobStatus key="status" jobStatusCode={job.status} />,
            canViewJobs ? (
              <Link
                key="title"
                boldOnHover
                data-text={job.position.name}
                onClick={event => {
                  event.stopPropagation()
                }}
                maxWidth={200}
                to={generatePath(paths.jobFlow, { id: job.jobId })}
                state={{ navigationJobs: jobsData.rows.map(job => job.jobId) }}
              >
                <TruncateWithTooltip variant="body2" maxWidth={200}>
                  {job.position.name}
                </TruncateWithTooltip>
              </Link>
            ) : (
              job.position.name
            ),

            job.department.name,
            job.project?.name ?? dash,
            location ?? dash,
            <JobTargetDate
              key="targetDate"
              targetDate={job.targetDate}
              performing={job.performing}
            />,
            recruiter ? (
              <ContactNameWithAvatar avatarProps={{ src: recruiter.avatar }}>
                {getNameWithStatus(recruiter.fullName, recruiter.active)}
              </ContactNameWithAvatar>
            ) : (
              dash
            ),
            <Tooltip
              key="priority"
              arrow
              placement="top"
              title={t(`jobs.priorities.${job.priority}`)}
            >
              <Box display="inline-flex">
                <priority.Icon sx={{ fontSize: '16px' }} />
              </Box>
            </Tooltip>,
            job.statistics.appliedCandidatesCountTotal,
            job.statistics.daysOpen,
            job.published ? t('common.yes') : t('common.no'),
          ],
        }
      })

      if (rows.length > 0) {
        return (
          <>
            <Table
              selected={selected}
              setSelected={setSelected}
              order={order}
              setOrder={setOrder}
              columns={columns}
              rows={rows}
              pagination={pagination}
              totalCount={jobsData.count}
              rowsClassName={previewCloseExceptionClass}
              isAdditionalHeaderVisible
              fullHeight
              additionalHeader={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TableTotalCount
                    count={jobsData.count}
                    totalCount={jobsData.totalCount}
                    isFiltersApplied={isFiltersApplied}
                    renderTotalText={({ boltTextComponent, count }) => (
                      <Trans
                        i18nKey="common.table_total_count.total_jobs"
                        values={{ value: count }}
                        components={{ valueWrapper: boltTextComponent }}
                      />
                    )}
                    Icon={IconBriefcase01}
                  />

                  {actionButtons}
                </Box>
              }
            />

            <PreviewDrawer
              isOpened={previewId !== null}
              onClose={() => setPreviewId(null)}
            >
              {previewId && (
                <JobPreview
                  jobId={previewId}
                  jobIds={jobsData.rows.map(({ jobId }) => jobId)}
                  setPreviewId={setPreviewId}
                />
              )}
            </PreviewDrawer>
          </>
        )
      }

      if (noDataTemplate) {
        return (
          <Box flex={1} justifyContent="center" alignItems="center">
            {noDataTemplate}
          </Box>
        )
      }

      if (isFiltersApplied) {
        return (
          <AbsolutelyCentered
            flex={1}
            justifyContent="center"
            alignItems="center"
          >
            <NoDataTemplate
              imgSrc={programmerImage}
              imgWidth={344}
              title={t('common.no_records')}
              subtitle={t('common.change_filters')}
              button={
                <Box display="flex" justifyContent="center">
                  {actionButtons}
                </Box>
              }
            />
          </AbsolutelyCentered>
        )
      }

      return (
        <AbsolutelyCentered>
          <NoDataTemplate
            imgSrc={programmerImage}
            imgWidth={344}
            title={t('jobs.no_data.no_data_title')}
            subtitle={
              canManageJob
                ? t('jobs.no_data.no_jobs_create_job_title')
                : t('jobs.no_data.no_jobs')
            }
            button={
              <Box display="flex" justifyContent="center">
                {actionButtons}
              </Box>
            }
          />
        </AbsolutelyCentered>
      )
    },
  })
}
