import { Box, Skeleton, Typography } from '@mui/material'
import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getMyProfile, logout, qk } from 'api'
import { useAuthContext } from 'app/auth'
import { paths } from 'app/paths'
import { useNotificationsFeed } from 'lib/app-helpers'
import { useBoolean } from 'lib/react-utils'
import mixpanel from 'mixpanel-browser'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Avatar } from 'ui/data'
import { Button } from 'ui/inputs/button'
import { Menu, MenuItem, NavLink } from 'ui/navigation'
import { TruncateWithTooltip } from 'ui/utils'

const AVATAR_SIZE = 40

export const MyProfile = () => {
  const $profile = useQuery(qk.auth.users.myProfile.toKey(), getMyProfile)
  const nameWidthRef = useRef<HTMLElement>(null)
  const [nameWidth, setNameWidth] = useState(0)

  useEffect(() => {
    if (nameWidthRef.current) {
      const width = nameWidthRef.current.offsetWidth
      setNameWidth(width)
    }
  }, [nameWidthRef])

  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { unauthorize } = useAuthContext()

  const { clearNotificationsFeed } = useNotificationsFeed()

  const $logout = useMutation(logout)

  const menuOpened = useBoolean()
  const menuAnchor = useRef<HTMLButtonElement | null>(null)

  const menuItems: Array<{ label: string; path: string }> = [
    {
      label: t('common.settings'),
      path: paths.personalData,
    },
  ]

  return (
    <>
      <Button
        ref={menuAnchor}
        onClick={menuOpened.toggle}
        noBorder
        data-cy="top-bar-profile-btn"
        variant="text"
        sx={{
          p: 0,
          minWidth: 0,
          color: 'inherit',
          justifyContent: 'start',
          textAlign: 'left',
          textTransform: 'none',
          width: menuOpened.isTrue ? nameWidth + AVATAR_SIZE + 20 : 40,
          overflow: 'hidden',
          transition: 'width 0.5s',
          ':hover': {
            [`& .MuiBox-root`]: {
              opacity: 1,
            },
            p: 1,
            pl: 0,
            width: nameWidth + AVATAR_SIZE + 20,
          },
        }}
      >
        <Avatar
          size={AVATAR_SIZE}
          src={$profile.data?.avatar}
          isLoading={$profile.isLoading}
        />
        <Box
          ml={1}
          ref={nameWidthRef}
          sx={{
            opacity: menuOpened.isTrue ? 1 : 0,
            transition: 'opacity 0.5s',
          }}
        >
          <Typography variant="body2">
            {$profile.data ? (
              <TruncateWithTooltip
                title={$profile.data.fullName}
                maxWidth={120}
              >
                {$profile.data.fullName}
              </TruncateWithTooltip>
            ) : (
              <Skeleton variant="text" width={120} />
            )}
          </Typography>
          <Typography
            variant="caption"
            color={theme => theme.palette.mineShaft[800]}
            display="block"
          >
            {$profile.data ? (
              $profile.data.role.name
            ) : (
              <Skeleton variant="text" width={60} />
            )}
          </Typography>
        </Box>
      </Button>

      <Menu
        anchorEl={menuAnchor.current}
        isOpen={menuOpened.isTrue}
        onClose={menuOpened.setFalse}
        placement="bottom-start"
        minWidth={nameWidth + AVATAR_SIZE + 12}
        ContainerProps={{ sx: { mt: '10px' } }}
      >
        {menuItems.map(menuItem => (
          <MenuItem
            divider
            key={menuItem.path}
            component={NavLink}
            to={menuItem.path}
            data-cy={`top-menu-${menuItem.label}`}
            onClick={menuOpened.toggle}
          >
            {menuItem.label}
          </MenuItem>
        ))}
        <MenuItem
          divider
          data-cy="top-menu-logout-btn"
          onClick={() =>
            $logout.mutate(undefined, {
              onSuccess: () => {
                unauthorize()
                queryClient.removeQueries()
                clearNotificationsFeed()
                Sentry.setUser(null)
                mixpanel.reset()
                navigate(paths.login)
              },
            })
          }
        >
          {t('common.log_out')}
        </MenuItem>
      </Menu>
    </>
  )
}
