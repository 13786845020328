import * as t from 'io-ts'
import { DateFromISOString } from 'io-ts-types'

export const TSubscriptionPeriodTypes = t.union([
  t.literal('DAY'),
  t.literal('MONTH'),
  t.literal('YEAR'),
])

export type SubscriptionPeriodTypes = t.TypeOf<typeof TSubscriptionPeriodTypes>

const TSubscriptionPaymentCurrency = t.literal('EUR')

export type SubscriptionPaymentCurrency = t.TypeOf<
  typeof TSubscriptionPaymentCurrency
>

const TSubscriptionCommon = t.intersection([
  t.strict({
    subscriptionId: t.string,
    name: t.string,
    periodType: TSubscriptionPeriodTypes,
    price: t.number,
  }),
  t.partial({
    currency: TSubscriptionPaymentCurrency,
    description: t.string,
    label: t.string,
    discount: t.number,
    discountedPrice: t.number,
    features: t.array(
      t.strict({
        code: t.string,
        name: t.string,
      }),
    ),
    abstracts: t.array(
      t.strict({
        thesis: t.string,
        order: t.number,
      }),
    ),
  }),
])

const TSubscriptionNoTrial = t.intersection([
  TSubscriptionCommon,
  t.strict({
    trialAvailable: t.literal(false),
  }),
])

export const TSubscriptionWithTrial = t.intersection([
  TSubscriptionCommon,
  t.strict({
    trialAvailable: t.literal(true),
    trialDuration: t.number,
    trialPeriodType: TSubscriptionPeriodTypes,
  }),
])

export type SubscriptionWithTrial = t.TypeOf<typeof TSubscriptionWithTrial>

const TBillingAddress = t.partial({
  email: t.string,
  country: t.string,
  city: t.string,
  street: t.string,
  suite: t.string,
  state: t.string,
  postal: t.string,
  vat: t.string,
})

export type BillingAddress = t.TypeOf<typeof TBillingAddress>

const TSubscriptionBase = t.intersection([
  t.strict({
    subscription: t.union([TSubscriptionWithTrial, TSubscriptionNoTrial]),
  }),
  t.partial({
    seats: t.number,
    availableSeats: t.number,
    /** Total price is the price calculated based on fixed price value and current seats amount */
    totalPrice: t.number,
    totalDiscountedPrice: t.number,
    nextPeriodTotalPrice: t.number,
    totalSeats: t.number,
    activeUsersCount: t.number,
    billingAddress: TBillingAddress,
    companyAccountType: t.union([t.literal('COMPANY'), t.literal('PERSONAL')]),
  }),
])

const TActiveSubscription = t.intersection([
  t.strict({
    currentlyActive: t.literal(true),
    status: t.literal('ACTIVE'),
    periodTo: DateFromISOString,
  }),
  t.partial({
    availableSeats: t.number,
  }),
])

const TTrialSubscription = t.strict({
  currentlyActive: t.literal(true),
  status: t.literal('TRIAL'),
  trialEndedAt: DateFromISOString,
  periodTo: DateFromISOString,
  periodFrom: DateFromISOString,
})

const TChangingSubscription = t.strict({
  currentlyActive: t.literal(true),
  status: t.literal('CHANGING'),
  periodTo: DateFromISOString,
  nextPeriodSubscriptionId: t.string,
  nextPeriodSubscriptionName: t.string,
})

const TCancelingSubscription = t.strict({
  currentlyActive: t.literal(true),
  status: t.literal('CANCELING'),
  cancelAt: DateFromISOString,
})

const TFrozenSubscription = t.intersection([
  t.strict({
    currentlyActive: t.literal(false),
    status: t.literal('FROZEN'),
  }),
  t.partial({
    frozenReason: t.union([
      t.literal('TRIAL_PERIOD_ENDED'),
      t.literal('PAYMENT_FAILED'),
      t.literal('NO_PAYMENT_METHOD'),
      t.string,
    ]),
  }),
])

export const TCurrentSubscription = t.intersection([
  TSubscriptionBase,
  t.union([
    TActiveSubscription,
    TTrialSubscription,
    TChangingSubscription,
    TCancelingSubscription,
    TFrozenSubscription,
  ]),
])

export type CurrentSubscription = t.TypeOf<typeof TCurrentSubscription>

const TAvailableSubscription = t.intersection([
  TSubscriptionBase,
  t.strict({
    currentlyActive: t.literal(false),
    status: t.undefined,
  }),
])

export const TSubscription = t.union([
  TCurrentSubscription,
  TAvailableSubscription,
])

export type Subscription = t.TypeOf<typeof TSubscription>

export const TSubscriptionChangePrice = t.strict({
  totalPrice: t.number,
  currency: TSubscriptionPaymentCurrency,
})

export type SubscriptionStatus =
  | 'ACTIVE'
  | 'TRIAL'
  | 'CHANGING'
  | 'CANCELING'
  | 'FROZEN'
