import { useState } from 'react'
import { InView } from 'react-intersection-observer'
import { Page } from 'react-pdf'

type Props = {
  pageNumber: number
  scale: number
  onView: () => void
}

export const DocumentPreviewPage = ({ pageNumber, scale, onView }: Props) => {
  const [ignoredFirstIntersection, setIgnoredFirstIntersection] =
    useState(false)

  const handleChange = (inView: boolean) => {
    if (!ignoredFirstIntersection && inView) {
      setIgnoredFirstIntersection(true)
      return
    }
    if (inView) {
      onView()
    }
  }

  return (
    <InView initialInView={false} onChange={handleChange}>
      <Page scale={scale / 100} width={820} pageNumber={pageNumber} />
    </InView>
  )
}
