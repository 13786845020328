import 'cropperjs/dist/cropper.css'
import './styles.css'

import { useBoolean } from 'lib/react-utils'
import { useEffect, useState } from 'react'
import { Cropper } from 'react-cropper'
import { useTranslation } from 'react-i18next'
import { Dialog, DialogContent } from 'ui/feedback'

type Props = {
  open: boolean
  image: Blob | null
  onCrop: (image: Blob) => void
  onClose: () => void
}

export const CropImageDialog = (props: Props) => {
  const { t } = useTranslation()
  const [imageUrl, setImageUrl] = useState('')

  const [cropper, setCropper] = useState<Cropper>()
  const isCropping = useBoolean()

  useEffect(() => {
    if (props.image) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        const result = reader.result
        setImageUrl(result as string)
      })

      reader.readAsDataURL(props.image)
    }
  }, [props.image])

  return (
    <Dialog
      open={props.open}
      size="small"
      onClose={props.onClose}
      isContentLoading={props.image === null}
    >
      <DialogContent
        title={t('common.set_up_profile_photo')}
        confirmText={t('common.save')}
        onDeny={props.onClose}
        isActionLoading={isCropping.isTrue}
        onConfirm={() => {
          if (cropper) {
            isCropping.setTrue()

            cropper.getCroppedCanvas().toBlob(
              blob => {
                isCropping.setFalse()

                if (blob) {
                  props.onCrop(blob)
                }
              },
              props.image?.type === 'image/png' ? 'image/png' : 'image/jpeg',
            )
          }
        }}
      >
        {props.image && (
          <Cropper
            src={imageUrl}
            viewMode={1} // restrict the crop box not to exceed the size of the canvas
            dragMode="move"
            aspectRatio={1}
            minCropBoxWidth={32}
            minCropBoxHeight={32}
            checkOrientation={false}
            guides={false}
            center={false}
            zoomable={false}
            movable={false}
            scalable={false}
            rotatable={false}
            background={false}
            onInitialized={instance => {
              setCropper(instance)
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}
