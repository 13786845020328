import { Box, FormHelperText, Stack, Typography } from '@mui/material'
import { IconX } from 'assets/icons'
import { InfoHint } from 'components/common'
import { forwardRef } from 'react'
import { Button } from 'ui/inputs/button'

export type FormControlProps = {
  children: React.ReactNode

  label?: string
  /** shows asterisk after label */
  required?: boolean
  /** info hint with tooltip near the label */
  hint?: string

  helperText?: React.ReactNode
  error?: boolean

  /** select binds label differently */
  onRemove?: () => void
  /** additional element right to the label text */
  labelAdditionalElement?: React.ReactNode

  name?: string
  minWidth?: string
}

export type FormControlWrapper = Omit<FormControlProps, 'children'>

export const FormControl = forwardRef<Element, FormControlProps>(
  (props, ref) => {
    return (
      <Box
        ref={ref}
        width="100%"
        display="inline-flex"
        flexDirection="column"
        minWidth={props.minWidth || '240px'}
        maxWidth="560px"
      >
        {(props.label || props.labelAdditionalElement) && (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {props.label && (
              <Stack direction="row" spacing="2px" mb="8px">
                <Typography variant="body1" color="mineShaft[900]">
                  {props.label}{' '}
                  {props.required && (
                    <Typography
                      component="span"
                      variant="body1"
                      color={'error.main'}
                    >
                      *
                    </Typography>
                  )}
                </Typography>
              </Stack>
            )}

            {props.hint && (
              <Box order={1} ml="auto">
                <InfoHint hint={props.hint} iconSize="14px" />
              </Box>
            )}

            {props.labelAdditionalElement && (
              <Box ml="auto" order={2} pl={1}>
                {props.labelAdditionalElement}
              </Box>
            )}
          </Box>
        )}

        <Box display="flex" width="100%">
          {props.children}

          {props.onRemove && (
            <Button
              icon
              variant="outlined"
              color="error"
              sx={{
                minWidth: '32px',
                height: '32px',
                ml: 2,
                alignSelf: 'center',
              }}
              onClick={props.onRemove}
            >
              <IconX sx={{ fontSize: '20px' }} />
            </Button>
          )}
        </Box>

        {props.helperText && (
          <FormHelperText
            error={props.error}
            sx={{
              mt: '8px',
              pr: props.onRemove ? 6 : 0,
              color: 'mineShaft[600]',

              '&.Mui-error': {
                color: 'error.main',
              },
            }}
          >
            <Typography
              variant="body2"
              data-cy={props.name ? `${props.name}HelperText` : undefined}
            >
              {props.helperText}
            </Typography>
          </FormHelperText>
        )}
      </Box>
    )
  },
)
